
import { Form, Formik, Field } from 'formik'
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers'
import Loader from '../Loader'


const Orders_View = ({ loadingEdit,setShowEditModal, handlePrint, initialEditValues, handleUpdateOrders, viewOrders,
    detailsLoading, loading
}: any) => {
    return (
        <>
            <div className="modal" role="dialog" style={{ display: 'block' }}>
                <div className="modal-dialog modal-xl modal-dialog-scrollable" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">Orders Details :</h3>
                            <button onClick={handlePrint} className="print-button print_top_btn">
                                Print
                            </button>
                            <button type="button" className="btn-close" onClick={() => setShowEditModal(false)} aria-label="Close"></button>
                        </div>
                        {loadingEdit ? (
                            <Loader />
                        ) : (
                            <div className="modal-body">
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <h6 className='fw-bold text-muted'>Order Id : <span className='fw-bold fs-6 text-dark'>{viewOrders?.order_id}</span></h6>
                                        <h6 className='fw-bold text-muted'>Customer Name : <span className='fw-bold fs-6 text-dark'>{viewOrders?.contact_person}</span></h6>
                                        <h6 className='fw-bold text-muted'>Customer Number : <span className='fw-bold fs-6 text-dark'>{viewOrders?.contact_number}</span></h6>
                                        <h6 className='fw-bold text-muted'>Address : <br></br><span className='fw-bold fs-6 text-dark'>{viewOrders?.address}</span></h6>
                                        <h6 className='fw-bold text-muted'>Total Items : <span className='fw-bold fs-6 text-dark'>{viewOrders?.order_items?.length}</span></h6>
                                    </div>
                                    <div className='col-md-6'>
                                        <h6 className='fw-bold text-muted'>Order On : <span className='fw-bold fs-6 text-dark'>{viewOrders?.order_on}</span></h6>
                                        <h6 className='fw-bold text-muted'>Order Status : <span className='fw-bold fs-6 text-dark'>
                                            {viewOrders?.order_status === "0" ? (
                                                <span className='badge bg-danger text-white'>Pending</span>
                                            ) : viewOrders?.order_status === "1" ? (
                                                <span className='badge bg-warning text-white'>Processing</span>
                                            ) : viewOrders?.order_status === "3" ? (
                                                <span className="badge bg-success text-white">Completed</span>
                                            ) : viewOrders?.order_status === "2" ? (
                                                <span className='badge bg-info text-white'>Rejected</span>
                                            ) : viewOrders?.order_status === "4" ? (
                                                <span className='badge bg-info text-white'>Out for delivery</span>
                                            ) : viewOrders?.order_status === "5" ? (
                                                <span className='badge bg-info text-white'>Getting Ready</span>
                                            ) : viewOrders?.order_status === "6" ? (
                                                <span className='badge bg-info text-white'>Order is Ready</span>
                                            ) : null}</span>
                                        </h6>
                                        <h6 className='fw-bold text-muted'>Order Total : <span className='fw-bold fs-6 text-dark'>{viewOrders?.order_total}</span></h6>
                                        <h6 className='fw-bold text-muted'>GST: <span className='fw-bold fs-6 text-dark'>{viewOrders?.gst}</span></h6>
                                        <h6 className='fw-bold text-muted'>Delivery Type :
                                            <span>
                                                {viewOrders?.delivery_type === 'pickup' || viewOrders?.delivery_type === 'Pickup' ? (
                                                    <span className='badge bg-info text-white fs-7 fw-bold'>
                                                        <KTIcon iconName='scooter' className='fs-3 me-1 text-white' />
                                                        Pickup
                                                    </span>
                                                ) : viewOrders?.delivery_type === 'delivery' || viewOrders?.delivery_type === 'Delivery' ? (
                                                    <span className='badge bg-primary text-white fs-7 fw-bold'>
                                                        <KTIcon iconName='delivery-time' className='fs-3 me-1 text-white' />
                                                        Home Delivery
                                                    </span>
                                                ) : ("-")}
                                            </span>

                                        </h6>
                                        <h6 className='fw-bold text-muted'>Expected Date : <span className='fw-bold fs-6 text-dark'>{viewOrders?.expected_date}</span></h6>
                                        <h6 className='fw-bold text-muted'>Delivery Date : <span className='fw-bold fs-6 text-dark'>{viewOrders?.order_completed_on ? viewOrders.order_completed_on : '-'}</span></h6>
                                        <h6 className='fw-bold text-muted'>Delivery By : <span className='fw-bold fs-6 text-dark'> {viewOrders?.order_completed_by ? viewOrders.order_completed_by : '-'}</span></h6>
                                    </div>
                                </div>
                                <h6 className='fw-bold text-muted'>Notes : <span className='fw-bold fs-6 text-dark'>{viewOrders?.special_instructions}</span></h6>
                                {detailsLoading ? (
                                    <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                                        <div className="spinner-border text-primary" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                ) : (viewOrders?.order_items && viewOrders.order_items.length > 0 ? (
                                    <div className='table-responsive'>
                                        <table className='table align-middle gs-0 gy-4 mt-3'>
                                            <thead>
                                                <tr className='fw-bold text-muted bg-light'>
                                                    <th className='ps-4 min-w-250px'>Item Name</th>
                                                    <th className='text-center min-w-150px'>Qty</th>
                                                    <th className='min-w-90px'>Price</th>
                                                    <th className='min-w-100px'>Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {viewOrders?.order_items.map((item: any, index: any) => (
                                                    <tr key={index}>
                                                        <td className='text-dark fw-bold text-hover-primary fs-6'>
                                                            {item.order_item_name}
                                                        </td>
                                                        <td className='text-dark fw-bold text-hover-primary fs-6 text-center'>
                                                            {item.order_item_qty}
                                                        </td>
                                                        <td className='text-dark fw-bold text-hover-primary fs-6'>
                                                            {item.order_item_price}
                                                        </td>
                                                        <td className='text-dark fw-bold text-hover-primary fs-6'>
                                                            {item.order_item_inline_total}
                                                        </td>
                                                    </tr>
                                                ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                ) :
                                    <div className="d-flex justify-content-center p-10">
                                        <div className="car">
                                            <img src={toAbsoluteUrl('/media/stock/coming_soon.png')} className='w-350px' />
                                            <h3 className="text-center">No orders found</h3>
                                        </div>
                                    </div>
                                )}
                                <Formik initialValues={initialEditValues} onSubmit={handleUpdateOrders}>
                                    {() => (
                                        <Form>
                                            <div className="form-group">
                                                <Field type="hidden" name="order_upd_id" className="form-control" />
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label mt-3 mb-3" htmlFor="order_upd_status">Status:</label>
                                                <Field
                                                    as="select"
                                                    name="order_upd_status"
                                                    aria-label="Select example"
                                                    className="form-select"
                                                >
                                                    <option value='0'>Order Pending</option>
                                                    <option value='1'>Order Processing</option>
                                                    <option value='2'>Order Rejected</option>
                                                    <option value='5'>Getting Ready</option>

                                                    {viewOrders?.delivery_type === 'delivery' || viewOrders?.delivery_type === 'Delivery' ? (
                                                        <>
                                                            <option value='4'>Out for Delivery</option>
                                                        </>
                                                    ) : (<>
                                                        <option value='6'>Order is Ready</option>
                                                    </>)}

                                                    <option value='3'>Order Delivered & Completed</option>
                                                </Field>
                                            </div>
                                            <label className='form-label mt-3 mb-3'>Admin Notes :</label>
                                            <Field
                                                as='textarea'
                                                name="order_upd_notes"
                                                className="form-control"
                                                rows={3}
                                            />
                                            <div className='d-flex justify-content-end mt-5'>
                                                <button type="button" className="btn btn-secondary me-2" onClick={() => setShowEditModal(false)} disabled={loading}>
                                                    Cancel
                                                </button>
                                                <button type='submit' className='btn btn-primary' disabled={loading}>
                                                    {!loading && 'Submit'}
                                                    {loading && (
                                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                                            Please wait...{' '}
                                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                        </span>
                                                    )}
                                                </button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        )}
                    </div>
                </div>
            </div >
        </>
    )
}

export default Orders_View