import React, { useState } from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers'
import { Form, Formik, ErrorMessage, FormikValues, Field } from 'formik'
import * as Yup from 'yup'
import Select from 'react-select';
import { CONTACTS_PUT_DATA } from '../../modules/auth/core/_requests';
import Loader from '../Loader';
import { useAuth } from '../../modules/auth';
import Swal from 'sweetalert2';

const ContactEditForm = ({ setShowEditModal, loadingEdit, initialEditValues,
    tagsSelected, setTagsSelected, tagsAdd, setTagsAdd, getTags, newTags, setNewTags, handleAddTags, handleTags, tagsError, setTagsError,
    fetchContactsList }: any) => {
    const [loading, setLoading] = useState(false)
    const { currentUser } = useAuth()
    const [user_id] = useState(currentUser?.user_id || '')
    const [user_token] = useState(currentUser?.api_token || '')
    const [customerId] = useState(currentUser?.cust_id || '')


    const validationSchemaEdit = Yup.object().shape({
        customer_name_upd: Yup.string().required('Customer Name is required'),
        customer_email_upd: Yup.string().email('Invalid email'),
    });

    const handleContactUpdate = async (values: any, actions: FormikValues) => {
        setLoading(true)
        try {
            const customerData = new FormData();
            const CategoryValues = tagsSelected.map((option: any) => option.value);
            customerData.append('contact_tags_upd', JSON.stringify(CategoryValues));
            const response = await CONTACTS_PUT_DATA(
                customerData,
                values.contact_id_upd,
                values.customer_name_upd,
                values.customer_email_upd,
                values.customer_phone_upd,
                values.customer_address_upd,
                user_id,
                user_token,
                customerId
            )
            if (response.data.message === "success") {
                actions.resetForm();
                setShowEditModal(false);
                setLoading(false);
                setTagsSelected([]);
                fetchContactsList("1", customerId);
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Successfully Updated',
                    showConfirmButton: true,
                    timer: 3000
                });
            } else if (response.data.message === "already_used") {
                setLoading(false);
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Mobile Number Already Exist',
                    showConfirmButton: true,
                    timer: 3000
                });
            } else {
                setLoading(false);
            }
        } catch (error) {
            console.error('Error submitting form data:', error);
        }
    }

    return (
        <>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Edit Details:</h5>
                    <button type="button" className="btn-close" onClick={() => setShowEditModal(false)} aria-label="Close"></button>
                </div>
                {loadingEdit ? (
                    <Loader />
                ) : (
                    <Formik initialValues={initialEditValues} validationSchema={validationSchemaEdit} onSubmit={handleContactUpdate}>
                        {() => (
                            <Form>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <input type="hidden" name="contact_id_upd" className="form-control" />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="customer_name_upd" className='form-label mb-3 required'>Customer Name</label>
                                        <Field type="text" name="customer_name_upd" className="form-control" />
                                        <ErrorMessage name="customer_name_upd" component="div" className="text-danger" />
                                    </div>

                                    <div className="form-group">
                                        <label className="form-label mt-3 mb-3 required" htmlFor="customer_phone_upd">Phone Number</label>
                                        <Field type="number" name="customer_phone_upd" className="form-control form-control-lg" readOnly />
                                        <ErrorMessage name="customer_phone_upd" component="div" className="text-danger" />
                                    </div>

                                    <div className="form-group">
                                        <label className="form-label mt-3 mb-3" htmlFor="customer_email_upd">Email Address</label>
                                        <Field type="text" name="customer_email_upd" className="form-control form-control-lg" />
                                        <ErrorMessage name="customer_email_upd" component="div" className="text-danger" />
                                    </div>

                                    <div className="form-group">
                                        <div className='mt-5'>
                                            <label htmlFor="contact_tags" className='form-label mt-3 mb-2'>Tags</label>
                                            <button type='button'
                                                className={`Plus_icon_btn ${tagsAdd ? 'd-none' : ''}`}
                                                onClick={() => setTagsAdd(true)} // Set tagsAdd to true when clicked
                                            >
                                                <i className="bi bi-plus plus_icon" />Add New
                                            </button>
                                            <button type='button'
                                                className={`Plus_icon_btn ${tagsAdd ? '' : 'd-none'}`}
                                                onClick={() => {
                                                    setTagsError(''); // Clear the error message
                                                    setTagsAdd(false); // Close the tags add section
                                                }}
                                            >
                                                <i className="bi bi-x plus_icon" />Close
                                            </button>
                                        </div>
                                        {tagsAdd && (
                                            <div className='d-flex'>
                                                <Field
                                                    type="text"
                                                    value={newTags}
                                                    name='tags'
                                                    onChange={(e: any) => {
                                                        setNewTags(e.target.value);
                                                        // Clear the error message when the user starts typing
                                                        setTagsError('');
                                                    }}
                                                    className="form-control mt-5"
                                                    placeholder='Enter New Tags'
                                                />
                                                <button
                                                    type='button'
                                                    onClick={() => handleAddTags({ tags: newTags })}
                                                    className="btn btn-primary Add_new_cate_btn"
                                                >
                                                    Add
                                                </button>
                                            </div>
                                        )}
                                        {tagsError && <div className="text-danger">{tagsError}</div>}
                                        <Select
                                            isMulti
                                            options={getTags
                                                .map((item: any) => ({
                                                    value: item.tag_name,
                                                    label: item.tag_name
                                                }))}
                                            value={tagsSelected}
                                            name='contact_tags'
                                            placeholder='Select Tags'
                                            onChange={(tagsSelected: string[]) => {
                                                handleTags(tagsSelected);
                                            }}
                                            className='w-100  mt-2'
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label className="form-label mt-3 mb-3" htmlFor="customer_address_upd" >Customer Address</label>
                                        <Field
                                            name="customer_address_upd"
                                            className="form-control"
                                            as="textarea"
                                        />
                                        <ErrorMessage name="customer_address_upd" component="div" className="text-danger" />
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <div className='d-flex justify-content-end'>
                                        <button type='submit' className='btn btn-primary' disabled={loading}>
                                            {!loading && 'Submit'}
                                            {loading && (
                                                <span className='indicator-progress' style={{ display: 'block' }}>
                                                    Please wait...{' '}
                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                </span>
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                )}
            </div>
        </>
    )
}

export default ContactEditForm