import React, { useCallback, useEffect, useState } from 'react'
import { Addons, whatspp_chat_message_post, whatspp_chat_template_post } from "../../../../auth/core/_requests"
import { useDropzone } from 'react-dropzone';
import { handleImageDrop } from '../../../../../pages/CompressImage';
import Swal from 'sweetalert2';
import { GET_TEMPLATES } from '../../../../../pages/GET';
import { SMSdata } from '../../../../../pages/Whatsapp_Interface/Interface';


interface Example {
    body_text: [string[], string[]];
}

interface Component {
    type: string;
    format?: string;
    text: string;
    example: Example;
}

interface DataItem {
    name: string;
    components: Component[];
    language: string;
    status: string;
    category: string;
    id: string;
}

interface ApiResponse {
    data: DataItem[];
}


const ChatTemplate = ({ setOpenModal, chatuser, chatStart }: any) => { 
    const [apiData, setApiData] = useState<ApiResponse | null>(null);
    const [pdfUpload, setPdfUpload] = useState<File[]>([]);
    const [pdfsizeError, setPdfsizeError] = useState('');
    const [imageUpload, setImageUpload] = useState<File[]>([]);
    const [uploadedVideo, setUploadedVideo] = useState(null);
    const [selectedTemplate, setSelectedTemplate] = useState<DataItem | null>(null);
    const [selectedTemplateName, setSelectedTemplateName] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [error, setError] = useState('');
    const [frontImageError, setFrontImageError] = useState('');
    const [variableError, setVariableError] = useState('');
    const [selectedVariables, setSelectedVariables] = useState<string[]>([]); 
    const [loading, setLoading] = useState(false);
    const [addons, setAddons] = useState<SMSdata[]>([]);

    let tokenData: any
    const AccessToken = async (addon: any) => {
      try {
        const response = await Addons(addon);
        setAddons(response.data.addon_details);
        if (response.data.addon_details) {
          const initialData = response.data.addon_details
          const addonMetaData = JSON.parse(initialData[0].addon_meta_data);
          tokenData = addonMetaData.access_token
          GET_TEMPLATES(tokenData, setApiData);
        }
      } catch (error) {
        console.error('Error fetching addons:', error);
      }
    };
  
    useEffect(() => {
      AccessToken("whatsapp");
    }, []);
     

    const handleSelectTemplate = (template: DataItem) => {
        setSelectedTemplate(template);
        setUploadedVideo(null)
        setPdfUpload([])
        setImageUpload([])
        setShowPopup(true);
        setSelectedVariables([]);
        setSelectedTemplateName(template.name);
    };

    const closePopup = () => {
        setShowPopup(false);
    };

    const onDrop = useCallback((acceptedFiles: any) => {
        const videoFile = acceptedFiles[0];
        if (videoFile && videoFile.size <= 5 * 1024 * 1024) { // Check if file size is less than or equal to 2MB
            setUploadedVideo(videoFile);
            setError('');
            setFrontImageError('')
        } else {
            setError('File size must be 5MB or less.');
            setUploadedVideo(null);
        }
    }, []);

    const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: { 'video/mp4': [] }, multiple: false });


    const handleDrop = (acceptedFiles: File[]) => {
        if (acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
            if (file.size <= 2 * 1024 * 1024) { // Checking if file size is less than 2MB
                setPdfUpload([file]);
                setPdfsizeError('');
                setFrontImageError('')
            } else {
                setPdfsizeError('PDF file size should be less than 2MB.');
            }
        }
    };

    const { getRootProps: getRootProps1, getInputProps: getInputProps1 } = useDropzone({
        onDrop: handleDrop,
        accept: {
            'application/pdf': [],
        },
        multiple: false
    });

    const { getRootProps: getRootProps2, getInputProps: getInputProps2 } = useDropzone({
        onDrop: acceptedFiles => handleImageDrop(acceptedFiles, setImageUpload),
        accept: {
            'image/jpeg': [],
            'image/png': [],
        }, multiple: false,
    });


    let variableData: any;

    if (selectedVariables.length > 0) {
        const parameters = selectedVariables.map((textValue: string) => ({
            type: "text",
            text: textValue,
        }));

        variableData = {
            "type": "body",
            "parameters": parameters
        };
    } else {
        // Handle case when there are no selectedVariables
        variableData = {
            "type": "body",
            "parameters": []  // Or provide default parameters if needed
        };
    }


    const filteredComponents = (selectedTemplate?.components || [])
        .filter(component => component.type === 'BODY' && typeof component.text === 'string')
        .map((filteredComponent, index) => (
            <p key={index} style={{ whiteSpace: 'pre-wrap' }}>
                {filteredComponent.text}
            </p>
        ));


    const replacePlaceholders = (text: any, replacements: any) => {
        let replacedText = text;
        replacements.forEach((value: any, index: any) => {
            const placeholder = `{{${index + 1}}}`;
            replacedText = replacedText.split(placeholder).join(value);
        });
        return replacedText;
    };

    const messageText = filteredComponents.map(component => component.props.children).join('\n');

    const replacedMessageText = replacePlaceholders(
        messageText,
        selectedVariables
    );

    const handleSubmit = async () => {
        if (selectedTemplate?.components.find(component => component.type === 'HEADER' &&
            component.format === "IMAGE" || component.format === "VIDEO" || component.format === "DOCUMENT",)) {
            if (!(pdfUpload.length > 0 || imageUpload.length > 0 || uploadedVideo)) {
                setFrontImageError('Please upload at least one file (PDF, Image, or Video).');
                return;
            }
        }
        if (selectedTemplate && selectedTemplate.components.find((component) =>
            typeof component.text === 'string' && component.text.includes('{{1}}')
        )) {
            if (selectedVariables.length === 0) {
                setVariableError('Please select variable and segment.');
                return;
            }
        }

        let templateDatas;

        const text_template = {
            "messaging_product": "whatsapp",
            "to": chatuser,
            "type": "template",
            "template": {
                "name": selectedTemplateName,
                "language": {
                    "code": "en_US"
                }
            }
        }

        const jsonDataTemplate = {
            "messaging_product": "whatsapp",
            "recipient_type": "individual",
            "to": chatuser,
            "type": "template",
            "template": {
                "name": selectedTemplateName,
                "language": {
                    "code": "en_US"
                },
                "components": [
                    variableData
                ]
            }
        };
        setLoading(true);
        try {
            const messageDatas = new FormData();
            if (pdfUpload.length > 0) {
                messageDatas.append("template_pdf", pdfUpload[0]);
            }
            if (imageUpload.length > 0) {
                messageDatas.append("template_image", imageUpload[0]);
            }
            if (uploadedVideo && uploadedVideo) {
                messageDatas.append("template_video", uploadedVideo);
            }
            let responseMessage;
            if (pdfUpload.length > 0 || imageUpload.length > 0 || uploadedVideo && uploadedVideo) {

                const response = await whatspp_chat_template_post(
                    messageDatas,
                );

                const broadcastImageLinks = response.data.message_header?.parameters?.map((item: any) => (
                    item.header_type?.header_link
                )) ?? [];
                const firstImageLink = broadcastImageLinks?.[0];
                const broadcastType = response.data.message_header?.parameters?.[0]?.type ?? undefined;
                const typevalue = broadcastType || ""


                if (typevalue && firstImageLink) {
                    templateDatas = {
                        "type": "header",
                        "parameters": [
                            {
                                "type": broadcastType || "",
                                [typevalue]: {
                                    "link": firstImageLink || ""
                                }
                            }
                        ]
                    };
                }

                const imageTemplate = {
                    "messaging_product": "whatsapp",
                    "recipient_type": "individual",
                    "to": chatuser,
                    "type": "template",
                    "template": {
                        "name": selectedTemplateName,
                        "language": {
                            "code": "en_US"
                        },
                        "components": [
                            templateDatas,
                            variableData
                        ]
                    }
                };

                if (response) {
                    setTimeout(async () => {
                        try {
                            const responseMessage = await whatspp_chat_message_post(chatuser, replacedMessageText, JSON.parse(JSON.stringify(imageTemplate)));
                            if (responseMessage.data === "success") {
                                chatStart(chatuser);
                            } else {
                                Swal.fire({
                                    icon: "error",
                                    title: "Oops...",
                                    text: "Something went wrong!",
                                });
                            }
                        } catch (error) {
                            console.error('Error sending message:', error);
                        }
                    }, 500);
                }
            } else if (selectedVariables.length > 0) {
                responseMessage = await whatspp_chat_message_post(chatuser, replacedMessageText, JSON.parse(JSON.stringify(jsonDataTemplate)));
                if (responseMessage.data === "success") {
                    chatStart(chatuser);
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                    });
                }
            } else {
                responseMessage = await whatspp_chat_message_post(chatuser, replacedMessageText, JSON.parse(JSON.stringify(text_template)));
                if (responseMessage.data === "success") {
                    chatStart(chatuser);
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                    });
                }
            }
            setLoading(false);
        } catch (error) {
            console.error('Error submitting form data:', error);
            setLoading(false);
        } finally {
            setShowPopup(false);
            setOpenModal(false)
        }
    };


    const handleVariableInputChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const selectedVariable = event.target.value;

        setSelectedVariables((prevSelectedVariables) => {
            const updatedVariables = [...prevSelectedVariables];
            updatedVariables[index - 1] = selectedVariable; // Adjust index to be zero-based
            return updatedVariables;
        });
        setVariableError('');
    };


    const getVariableCount = (text: string) => {
        const count = (text.match(/{{\d+}}/g) || []).length;
        return count;
    };

    const renderVariableSelects = () => {
        const bodyText = selectedTemplate?.components.find(component => component.type === 'BODY')?.text || '';

        const TotalvariableCount = getVariableCount(bodyText);
        const inputs = [];

        for (let i = 1; i <= TotalvariableCount; i++) {
            inputs.push(
                <input
                    key={i}
                    type="text"
                    className="form-control mb-5"
                    onChange={(event) => handleVariableInputChange(event, i)}
                    placeholder={`Enter variable ${i}`}
                    style={{ width: '30%' }}
                />
            );
        }

        return inputs;
    };

    return (
        <>
            {apiData?.data.map((item) => (
                <>
                    <div className="mt-5">
                        <div className=''>
                            <div key={item.id} className='bg-light p-8'>
                                <div className='d-flex justify-content-between'>
                                    <h4>{item.name}</h4>
                                    <button className='btn btn-sm btn-light-primary border border-primary' onClick={() => handleSelectTemplate(item)}>Select</button>
                                </div>
                                <p>
                                    {item.components.map((component, index) => (
                                        <>
                                            <p className='badge badge-danger text-white'>{component?.format}</p>
                                            <div key={index} style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: component.text }} />
                                        </>
                                    ))}
                                </p >
                            </div>
                            <div className='d-flex mt-5 ms-5'>
                                <h6 className='fw-bold text-muted me-5'>Language : <span className='fw-bold fs-6 text-dark'>{item.language}</span></h6>
                                <h6 className='fw-bold text-muted'>Category Type: <span className='fw-bold fs-6 text-dark'>{item.category}</span></h6>
                            </div>
                        </div>
                    </div>
                </>
            ))}

            {showPopup && selectedTemplate && (
                <div className="modal" role="dialog" style={{ display: 'block' }}>
                    <div className="modal-dialog modal-lg modal-dialog-scrollable" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{selectedTemplate.name}</h5>
                                <button type="button" className="btn-close" aria-label="Close" onClick={closePopup}></button>
                            </div>
                            <div className="modal-body">
                                <p>
                                    {selectedTemplate.components.map((component, index) => (
                                        <>
                                            <p className='badge badge-danger text-white'>{component?.format}</p>
                                            <div key={index} style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: component.text }} />
                                        </>
                                    ))}
                                </p>
                                <div className='separator border border-secondary'></div>
                                <div className='mt-10'>
                                    {selectedTemplate.components.map((component, index) => (
                                        <>
                                            <p key={index}>
                                                {component?.format === "VIDEO" ? (
                                                    <>
                                                        <label className="form-label mt-3 mb-3 required" htmlFor="upload_file">Video Upload</label>
                                                        {error && <p className="text-danger">{error}</p>}
                                                        <div className='d-flex'>
                                                            <div {...getRootProps({ className: "dropzone" })} >
                                                                <input {...getInputProps()} />
                                                                <p>Drag 'n' drop a video file, or click to select a file</p>
                                                            </div>
                                                            <div className='ms-5'>
                                                                {uploadedVideo && (
                                                                    <>
                                                                        <video controls className='w-250px'>
                                                                            <source src={URL.createObjectURL(uploadedVideo)} />
                                                                        </video>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                        {frontImageError && <div className="text-danger mt-2">Please upload a video.</div>}
                                                    </>
                                                ) : component?.format === "IMAGE" ? (
                                                    <>
                                                        <label className="form-label mt-3 mb-3 required" htmlFor="upload_file">Image Upload</label>
                                                        <div className='d-flex'>
                                                            <div {...getRootProps2({ className: "dropzone" })} >
                                                                <input className="input-zone" {...getInputProps2()} name='back_image' />
                                                                <div className="text-center">
                                                                    <p className="dropzone-content">
                                                                        Drag’n’drop some files here, or click to select files
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className='ms-5'>
                                                                {imageUpload.map(file => (
                                                                    <div key={file.name} style={{ margin: '10px' }}>
                                                                        <img
                                                                            src={typeof file === 'string' ? file : URL.createObjectURL(file)}
                                                                            alt={file.name}
                                                                            className='w-250px'
                                                                        />
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                        {frontImageError && <div className="text-danger mt-2">Please upload a image.</div>}
                                                    </>
                                                ) : component?.format === "DOCUMENT" ? (
                                                    <>
                                                        <label className="form-label mt-3 mb-3 required" htmlFor="upload_file">Document Upload</label>
                                                        {pdfsizeError && <div className="text-danger mb-2">{pdfsizeError}</div>}
                                                        <div {...getRootProps1({ className: "dropzone" })}>
                                                            <input type="file" id="upload_file" {...getInputProps1()} accept=".png,.jpeg" />
                                                            <div className="text-center">
                                                                <p className="dropzone-content">
                                                                    Drag’n’drop or click to select files
                                                                </p>
                                                            </div>
                                                        </div>
                                                        {pdfUpload.map(file => (
                                                            <div key={file.name} className='mt-5'>
                                                                <p className='badge bg-danger text-white p-3'> {file.name}</p>
                                                            </div>
                                                        ))}
                                                        {frontImageError && <div className="text-danger mt-2">Please upload a pdf.</div>}
                                                    </>
                                                ) : ""}
                                            </p>
                                        </>
                                    ))}
                                    <label className="required form-label">Variables</label>
                                    {renderVariableSelects()}
                                    {variableError && <div className="text-danger mt-2">{variableError}</div>}
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary me-2" onClick={closePopup}>
                                    Cancel
                                </button>
                                <div className='d-flex justify-content-end'>
                                    <button className="btn btn-primary" type="submit" onClick={handleSubmit} disabled={loading}>
                                        {!loading ? 'Submit' : (
                                            <span className='indicator-progress' style={{ display: 'inline-block' }}>
                                                Please wait...{' '}
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default ChatTemplate