import React, { useState, useCallback } from 'react'
import { useDropzone } from 'react-dropzone';
import { Field, Form, Formik, FormikValues } from 'formik'
import { whatspp_chat_image_post } from "../../../../auth/core/_requests" 

const ChatVideo = ({ chatuser, setVideoModel, chatStart }: any) => {
    const [loading, setLoading] = useState(false)
    const [uploadedVideo, setUploadedVideo] = useState(null);
    const [error, setError] = useState('');
    const [pdfsizeError, setPdfsizeError] = useState(false);
    const sender_id = "919943497572";


    const onDrop = useCallback((acceptedFiles: any) => {
        const videoFile = acceptedFiles[0];
        if (videoFile && videoFile.size <= 5 * 1024 * 1024) { // Check if file size is less than or equal to 2MB
            setUploadedVideo(videoFile);
            setError('');
        } else {
            setError('File size must be 5MB or less.');
            setUploadedVideo(null);
        }
    }, []);

    const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: { 'video/mp4': [] }, multiple: false });

    const handleClear = () => {
        setUploadedVideo(null); // Clear uploaded images
    };

    const handleSubmit = async (values: any, actions: FormikValues) => {
        if (!uploadedVideo) {
            setPdfsizeError(true);
            return;
        } else {
            setPdfsizeError(false);
        }
        setLoading(true);
        try {
            const ChatMedia = new FormData();
            if (uploadedVideo && uploadedVideo) {
                ChatMedia.append("chat_media", uploadedVideo);
            }
            const response = await whatspp_chat_image_post(
                ChatMedia,
                'video',
                values.message_caption ? values.message_caption : '',
                chatuser,
                sender_id
            );
            setLoading(false);
            chatStart(chatuser);
            setVideoModel(false)
            setUploadedVideo(null);
            actions.resetForm();
        } catch (error) {
            console.error('Error reading file:', error);
            setLoading(false);
        }
    };
    return (
        <>
            <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title">
                            <h3>Document Upload</h3>
                        </div>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => setVideoModel(false)} aria-label="Close"></button>
                    </div>
                    <Formik initialValues={{ file: null }} onSubmit={handleSubmit} >
                        <Form>
                            <div className="modal-body">
                                <div>
                                    <label className="form-label mt-3 mb-3 required" htmlFor="upload_file">Video Upload</label>
                                    {error && <p className="text-danger">{error}</p>}
                                    <div className='d-flex'>
                                        <div {...getRootProps({ className: "dropzone" })} >
                                            <input {...getInputProps()} />
                                            <p>Drag 'n' drop a video file, or click to select a file</p>
                                        </div> 
                                        <div className='ms-5'>
                                            {uploadedVideo && (
                                                <>
                                                    <video controls className='w-250px'>
                                                        <source src={URL.createObjectURL(uploadedVideo)} />
                                                    </video>
                                                </>
                                            )}
                                        </div> 
                                    </div>
                                    {pdfsizeError && <div className="text-danger mt-2">Please Upload Video.</div>}
                                </div>
                                {uploadedVideo && (
                                    <div className="form-group mt-5 mb-3">
                                        <Field
                                            name="message_caption"
                                            className="form-control w-50"
                                            as="textarea"
                                            placeholder="Add a Caption..."
                                        />
                                    </div>
                                )}
                            </div>
                            <div className="modal-footer">
                                {/* <div className="row col-12"> */}
                                <button type='submit' className='btn btn-primary' disabled={loading}>
                                    {!loading && 'Send'}
                                    {loading && (
                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                            Please wait...{' '}
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                </button>
                                {/* </div> */}
                            </div>
                        </Form>
                    </Formik>
                </div>
            </div>
        </>
    )
}

export default ChatVideo 