import React, { useState, useEffect } from 'react';
import { KTIcon, toAbsoluteUrl } from '../../../../../_metronic/helpers';
import {
  whatsapp_broadcast_segment_GET, UserDeatils_Post, whatsapp_broadcast_download, whatsapp_broadcast_create, whatsapp_broadcast_GET, whatspp_broadcast_post, Segment_DELETE_data, whatsapp_RECIPIENTS_GET, Addons,
  Segment_Recipients_Update
} from "../../../auth/core/_requests"
import { useAuth } from '../../../auth';
import Swal from 'sweetalert2';
import Pagination from '../../../../pages/Pagination';
import * as XLSX from 'xlsx';
import Loader from '../../../../pages/Loader';
import { SMSdata } from '../../../../pages/Whatsapp_Interface/Interface';
import { GET_TEMPLATES } from '../../../../pages/GET';
import Segment from '../Segment/Segment';
import SegmentFilterForm from '../Segment/SegmentFilterForm';
import Template from './template/Template';
import TemplateModal from './template/TemplateModal';
import BroadcastForm from './BroadcastForm';

interface CustomersData {
  order_person_name: string;
  order_contact_number: string;
  delivery_address_1: string;
  delivery_address_2: string;
}

interface Example {
  body_text: [string[], string[]];
}

interface Component {
  type: string;
  format?: string;
  text: string;
  example: Example;
}

interface DataItem {
  name: string;
  components: Component[];
  language: string;
  status: string;
  category: string;
  id: string;
}

interface ApiResponse {
  data: DataItem[];
}

interface UserDetails {
  id: string;
  name: string;
  phone: string;
  state: string;
  city: string;
  dob: string;
  status: string;
  email: string;
  address: string;
  doj: string;
}

interface SegmentsDatas {
  segment_id: string;
  segment_created_by: string;
  segment_customers: string;
  segment_created_on: string;
  segment_name: string;
  segment_doc_link: string;
}

interface BroadcastDatas {
  broadcast_id: string;
  broadcast_segment_id: string;
  broadcast_name: string;
  broadcast_type: string;
  broadcast_schedule_date: string;
  broadcast_schedule_time: string;
  broadcast_created_on: string;
  broadcast_created_by: string;
  broadcast_recipients: string;
  broadcast_failed_receipients: string;
  broadcast_message_delivered_count: number;
  broadcast_message_read_count: number;
  broadcast_message_sent_count: number;
}

interface SegmentCustomers {
  segment_id: string;
  segment_name: string;
  segment_customers: CustomersData[] | string;
  segment_created_by: string;
  segment_created_on: string;
  segment_doc_link: string;
}

interface Parameter {
  type: string;
  text?: string;
  header_type?: { header_link: string };
}

interface Template {
  name: string;
  language: {
    code: string;
  };
  components: Array<{
    type: string;
    parameters: Parameter[];
  }>;
}

interface Text_Template {
  name: string;
  language: {
    code: string;
  };
}

interface Message {
  messaging_product: string;
  recipient_type: string;
  to: string;
  type: string;
  template: Template;
}

interface Text_Message {
  messaging_product: string;
  to: string;
  type: string;
  template: Text_Template;
}

interface BroadcastHeader {
  type: string;
  parameters: Parameter[];
}

interface ReportDatas {
  contact_number: string;
  sent_on: string;
  delivered_on?: string;
  read_on?: string;
  created_on: string;
}


const Broadcast = () => {
  const { currentUser } = useAuth()
  const [showCard, setShowCard] = useState(true);
  const [showTabs, setShowTabs] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [customers, setCustomers] = useState<CustomersData[]>([]);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isApplied, setIsApplied] = useState(false);
  const [segmentData, setSegentData] = useState<SegmentsDatas[]>([]);
  const [segmentName, setSegmentName] = useState('');
  const [apiData, setApiData] = useState<ApiResponse | null>(null);
  const [addons, setAddons] = useState<SMSdata[]>([]);
  const [pdfUpload, setPdfUpload] = useState<File[]>([]);
  const [imageUpload, setImageUpload] = useState<File[]>([]);
  const [uploadedVideo, setUploadedVideo] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState<DataItem | null>(null);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<string[]>([]);
  const [selectedSegmentId, setSelectedSegmentId] = useState<string[]>([]);
  const [showAdditionalContent, setShowAdditionalContent] = useState(false);
  const [selectedTemplateName, setSelectedTemplateName] = useState('');
  const [selectedVariables, setSelectedVariables] = useState<string[]>([]);
  const [showBroadCast, setShowBroadCast] = useState(false);
  const [segmentCustomers, setSegmentCustomers] = useState<SegmentCustomers[]>([])
  const [segmentImage, setSegmentImage] = useState(null)
  const [broadcastHeader, setBroadcastHeader] = useState<BroadcastHeader | undefined>();
  const [frontImageError, setFrontImageError] = useState('');
  const [errorShow, setErrorShow] = useState('');
  const [variableError, setVariableError] = useState('');
  const [selectedNames, setSelectedNames] = useState<string[]>([]);
  const [broadcastdetails, setBroadcastDetails] = useState<BroadcastDatas[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [refreshbroadcastdata, setRefreshBroadcastdata] = useState(false);
  const [reportData, setReportData] = useState<ReportDatas[]>([]);
  const [filterReportData, setFilterReportData] = useState<ReportDatas[]>([]);
  const [reportModel, setReportModel] = useState(false)
  const [reportTotalData, setReportTotalData] = useState<number>(1);
  const [receiveData, setReceiveData] = useState<SegmentCustomers[]>([])
  const [receiveModel, setReceivetModel] = useState(false)
  const [receiveTotalData, setReceiveTotalData] = useState<number>(1);
  const [searchQuery, setSearchQuery] = useState('');


  const [userID] = useState(currentUser?.user_id || '')
  const [customerId] = useState(currentUser?.cust_id || '')

  const itemsPerPage = 5;
  const totalItems = broadcastdetails.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const recentBroadcast = broadcastdetails.slice(startIndex, startIndex + itemsPerPage);


  const ReceiveDataPerPage = 10;
  const totalReceiveData = receiveData.reduce((total, segment) =>
    total + (typeof segment.segment_customers === 'string' ?
      JSON.parse(segment.segment_customers).length : 0), 0);
  const totalReceivePages = Math.ceil(totalReceiveData / ReceiveDataPerPage);
  const ReceiveIndex = (receiveTotalData - 1) * ReceiveDataPerPage;
  const ReceiveLists = receiveData.flatMap(segment =>
    typeof segment.segment_customers === 'string' ? JSON.parse(segment.segment_customers) : []);


  useEffect(() => {
    const filtered = reportData?.filter((app: any) => {
      const appData = `${app.contact_number}`.toLowerCase();
      return appData.includes(searchQuery.toLowerCase());
    });
    setFilterReportData(filtered);
  }, [searchQuery, reportData]);


  const ReportDataPerPage = 10;
  const totalReportData = reportData.length;
  const filterDataTotal = filterReportData.length
  const totalReportPages = Math.ceil(totalReportData / ReportDataPerPage);
  const filterTotalPage = Math.ceil(filterDataTotal / ReportDataPerPage);
  const ReportIndex = (reportTotalData - 1) * ReportDataPerPage;
  const ReportLists = filterReportData.slice(ReportIndex, ReportIndex + ReportDataPerPage);

  let tokenData: any
  const AccessToken = async (addon: any) => {
    try {
      const response = await Addons(addon);
      setAddons(response.data.addon_details);
      if (response.data.addon_details) {
        const initialData = response.data.addon_details
        const addonMetaData = JSON.parse(initialData[0].addon_meta_data);
        tokenData = addonMetaData.access_token
        GET_TEMPLATES(tokenData, setApiData);
      }
    } catch (error) {
      console.error('Error fetching addons:', error);
    }
  };

  useEffect(() => {
    AccessToken("whatsapp");
  }, []);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleReportPageChange = (page: number) => {
    setReportTotalData(page);
  };

  //const [replacementValuesArray, setReplacementValuesArray] = useState<any[]>([]);
  //console.log(selectedTemplate,'selecetd template id');
  const userContacts: string[] = [];
  const personNames = segmentCustomers.flatMap((item) => {

    if (Array.isArray(item.segment_customers)) {
      return item.segment_customers.map((customer) => {
        userContacts.push(customer.order_contact_number);
        return {
          order_person_name: customer.order_person_name,
          order_contact_number: customer.order_contact_number,
        };
      });
    }
    else if (typeof item.segment_customers === 'string') {
      try {
        const parsedData = JSON.parse(item.segment_customers);
        const mappedData = parsedData.map((customer: any) => ({
          order_person_name: customer.order_person_name,
          order_contact_number: customer.order_contact_number,
        }));

        // Push contact numbers to userContacts
        mappedData.forEach((customer: any) => {
          userContacts.push(customer.order_contact_number);
        });

        return mappedData;
      } catch (error) {
        console.error("Error parsing JSON:", error);
        return []; // Handle parsing error gracefully
      }
    } else {
      console.error("Invalid segment_customers type:", typeof item.segment_customers);
      return []; // Handle other cases gracefully
    }

  });

  //setPersonDetailsArray((prevArray) => [...prevArray, personNames]);
  //setSelectedFinalData(prevData => [...prevData, ...personNames]);

  //console.log(userContacts,'contacts')

  const handleAddBroadcastClick = () => {
    setShowCard(!showCard);
    setShowTabs(!showTabs);
  };

  const handleBackButtonClick = () => {
    setShowCard(true); // Simulate going back by showing the card again
    setShowTabs(false); // Hide the tabs again
  };
  const OpenFilterModel = () => {
    setShowModal(true);
  };

  const fetchSegmentsData = async (all_segments: any) => {
    try {
      const response = await whatsapp_broadcast_segment_GET(all_segments);
      setSegentData(response.data.broadcast_segments);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching members:', error);
    }
  };

  useEffect(() => {
    fetchSegmentsData("0");
  }, []);


  const fetchBroadcastData = async (all_segments: any) => {
    try {
      const response = await whatsapp_broadcast_GET(all_segments);
      setBroadcastDetails(response.data.broadcast_details);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching members:', error);
    }
  };

  useEffect(() => {
    fetchBroadcastData("0");
  }, []);



  const handleContinueButtonClick = () => {
    setShowAdditionalContent(true);
    setShowTabs(false);
    setSelectedCheckboxes([]);
  };

  const handleSelectTemplate = (template: DataItem) => {
    setSelectedTemplate(template);
    setUploadedVideo(null)
    setPdfUpload([])
    setImageUpload([])
    setShowPopup(true);
    setSelectedVariables([]);
    setSelectedTemplateName(template.name); // Store the selected template ID
  };



  const handleContinueTemplate = async () => {
    if (selectedTemplate?.components.find(component => component.type === 'HEADER' &&
      component.format === "IMAGE" || component.format === "VIDEO" || component.format === "DOCUMENT",)) {
      if (!(pdfUpload.length > 0 || imageUpload.length > 0 || uploadedVideo)) {
        setFrontImageError('Please upload at least one file (PDF, Image, or Video).');
        return;
      }
    }


    if (selectedTemplate && selectedTemplate.components.find((component) =>
      typeof component.text === 'string' && component.text.includes('{{1}}')
    )) {
      if (selectedVariables.length === 0) {
        setVariableError('Please select variable and segment.');
        return;
      }
    }

    setLoading(true);

    try {
      const customerDatas = new FormData();
      if (pdfUpload.length > 0) {
        customerDatas.append("template_pdf", pdfUpload[0]);
      }
      if (imageUpload.length > 0) {
        customerDatas.append("template_image", imageUpload[0]);
      }
      if (uploadedVideo && uploadedVideo) {
        customerDatas.append("template_video", uploadedVideo);
      }

      const segmentDatas = await whatspp_broadcast_post(
        customerDatas,
        selectedVariables,
        selectedSegmentId
      );

      const data = segmentDatas.data
      setSegmentCustomers(data.broadcast_segments)
      setSegmentImage(data.broadcast_doc_link)
      setBroadcastHeader(data.broadcast_header);
      setLoading(false);
    } catch (error) {
      console.error('Error submitting form data:', error);
    } finally {
      // setSelectedSegmentId([]);
      setShowBroadCast(true);
      setShowPopup(false);
      setShowAdditionalContent(false);
    }
  };

  // useEffect(() => {
  //   // Update the state with all replacementValues at once, after the loop
  //   setReplacementValuesArray(allReplacementValues || []); // Provide a default value of an empty array
  // }, [allReplacementValues]);

  const handleBackButtonTemplate = () => {
    setShowTabs(!showTabs);
    setShowAdditionalContent(false);
    setShowBroadCast(false);
    setSelectedSegmentId([]);
  }


  if (!apiData) {
    return (
      <Loader />
    );
  }


  const broadcastImageLinks = broadcastHeader?.parameters?.map((item) => (
    item.header_type?.header_link
  )) ?? [];

  const firstImageLink = broadcastImageLinks?.[0];
  const broadcastType = broadcastHeader?.parameters?.[0]?.type ?? undefined;
  const typevalue = broadcastType || ""

  let templateDatas: any;

  if (typevalue && firstImageLink) {
    templateDatas = {
      "type": "header",
      "parameters": [
        {
          "type": broadcastType || "",
          [typevalue]: {
            "link": firstImageLink || ""
          }
        }
      ]
    };
  }

  const handleSubmitBroadCast = async (values: any) => {
    // Create a new array to store replacement values
    const replacementValuesArray: any[][] = [];

    const allReplacementValues = selectedTemplate && selectedTemplate.components
      .filter(component => typeof component.text === 'string' && component.text.includes('{{1}}'))
      .flatMap((filteredComponent, index) => {
        return personNames.map((personDetail, personIndex) => {

          if (personDetail && typeof personDetail === 'object') {
            const replacementValues = selectedVariables?.map(item => {
              return personDetail.hasOwnProperty(item) ? personDetail[item] : null;
            }) || [];
            replacementValuesArray.push(replacementValues);

            return replacementValues;
          } else {
            console.error('Invalid personDetail type:', typeof personDetail);
            return [];
          }
        });
      });

    // Your JSON data template
    const jsonDataTemplate: Message = {
      "messaging_product": "whatsapp",
      "recipient_type": "individual",
      "to": userContacts.length > 0 ? userContacts[0] : "fsdfsfsf", // Use the first contact or fallback to "fsdfsfsf"
      "type": "template",
      "template": {
        "name": selectedTemplateName,
        "language": {
          "code": "en_US"
        },
        "components": [
          ...(templateDatas ? [templateDatas] : []),
          {
            "type": "body",
            "parameters": [
              {
                "type": "text",
                "text": "123"
              }

            ]
          }
        ]
      }
    };

    const text_template = {
      "messaging_product": "whatsapp",
      "to": userContacts.length > 0 ? userContacts[0] : "fsdfsfsf",
      "type": "template",
      "template": {
        "name": selectedTemplateName,
        "language": {
          "code": "en_US"
        }
      }
    }

    const headerTemplate: Message = {
      "messaging_product": "whatsapp",
      "recipient_type": "individual",
      "to": userContacts.length > 0 ? userContacts[0] : "fsdfsfsf", // Use the first contact or fallback to "fsdfsfsf"
      "type": "template",
      "template": {
        "name": selectedTemplateName,
        "language": {
          "code": "en_US"
        },
        "components": [
          ...(templateDatas ? [templateDatas] : []),

        ]
      }
    };

    const cloneTemplate = (template: Message, index: number, replacementValuesArray: any[][], userContacts: string[]): Message => {
      const clonedTemplate: Message = JSON.parse(JSON.stringify(template)); // Deep clone to avoid modifying the original template

      clonedTemplate.template.components.forEach((component, componentIndex) => {
        if (component.parameters && replacementValuesArray[componentIndex] && component.type === 'body') {
          component.parameters = replacementValuesArray[index].map((textValue) => ({
            type: "text",
            text: textValue,
          }));
        }
      });

      // Update "to" property with the current contact from userContacts
      clonedTemplate.to = userContacts[index] || "fsdfsfsf"; // Fallback to "fsdfsfsf" if userContacts is shorter

      return clonedTemplate;
    };

    const cloneTemplateText = (template: Text_Message, index: number, userContacts: string[]): Text_Message => {
      const clonedTemplate: Text_Message = JSON.parse(JSON.stringify(template)); // Deep clone to avoid modifying the original template
      // Update "to" property with the current contact from userContacts
      clonedTemplate.to = userContacts[index] || "fsdfsfsf"; // Fallback to "fsdfsfsf" if userContacts is shorter
      return clonedTemplate;
    };

    const cloneTemplatemedia = (template: Message, index: number, userContacts: string[]): Message => {
      const clonedTemplate: Message = JSON.parse(JSON.stringify(template)); // Deep clone to avoid modifying the original template

      // Update "to" property with the current contact from userContacts
      clonedTemplate.to = userContacts[index] || "fsdfsfsf"; // Fallback to "fsdfsfsf" if userContacts is shorter

      return clonedTemplate;
    };

    const filteredComponents = (selectedTemplate?.components || [])
      .filter(component => component.type === 'BODY' && typeof component.text === 'string')
      .map((filteredComponent, index) => (
        <p key={index} style={{ whiteSpace: 'pre-wrap' }}>
          {filteredComponent.text}
        </p>
      ));
    const messageText = filteredComponents.map(component => component.props.children).join('\n');

    function replacePlace(messages: string, replacements: string[][], numbers: string[]) {
      if (!messages.includes('{{1}}')) {
        // If the message doesn't contain '{{1}}', return the message with the corresponding phone number
        return numbers.map((message: string, index: number) => {
          const number = numbers[index];
          return {
            template: messages,
            number
          };
        });
      }

      // Handle messages with placeholders
      return replacements.map((replacement: string[], index: number) => {
        const template = messages.replace('{{1}}', replacement[0]);
        return {
          template,
          number: numbers[index]
        };
      });
    }

    const templateMessage = replacePlace(messageText, replacementValuesArray, userContacts);

    const numberOfTemplates = replacementValuesArray.length;
    const templates: Message[] = [];
    const text_templates: Text_Message[] = [];
    if (numberOfTemplates == 0 && templateDatas == undefined) {
      for (let i = 0; i < userContacts.length; i++) {
        const newTemplate = cloneTemplateText(text_template, i, userContacts);
        // newTemplate.to = userContacts[i] || "fsdfsfsf"; // Fallback to "fsdfsfsf" if userContacts is shorter
        text_templates.push(newTemplate);
      }
    }
    else if (numberOfTemplates == 0 && templateDatas != undefined) {
      for (let i = 0; i < userContacts.length; i++) {
        const newTemplate = cloneTemplatemedia(headerTemplate, i, userContacts);
        // newTemplate.to = userContacts[i] || "fsdfsfsf"; // Fallback to "fsdfsfsf" if userContacts is shorter
        templates.push(newTemplate);
      }
    }
    else {
      for (let i = 0; i < numberOfTemplates; i++) {
        const newTemplate = cloneTemplate(jsonDataTemplate, i, replacementValuesArray, userContacts);
        // newTemplate.to = userContacts[i] || "fsdfsfsf"; // Fallback to "fsdfsfsf" if userContacts is shorter
        templates.push(newTemplate);
      }
    }
    setLoading(true);
    try {
      let schedule_date = values.schedule_date;
      let schedule_time = values.schedule_time;
      let template_choice: any = [];
      let broadcast_recipients: any = '';

      // Check if delivery_type is not 'schedule', then set schedule_date and schedule_time to empty strings
      if (values.delivery_type !== 'schedule') {
        schedule_date = '';
        schedule_time = '';
      }

      if (text_templates.length > 0) {
        template_choice = text_templates;
        broadcast_recipients = userContacts.length;
      }
      else if (numberOfTemplates == 0 && templateDatas != undefined) {
        template_choice = templates;
        broadcast_recipients = userContacts.length;
      }
      else {
        template_choice = templates;
        broadcast_recipients = replacementValuesArray.length;
      }

      const response = await whatsapp_broadcast_create(
        values.broadcast_name,
        values.delivery_type,
        schedule_date,
        schedule_time,
        template_choice,
        broadcast_recipients,
        selectedSegmentId,
        templateMessage
      );
      if (response.data.message == "success") {
        setTimeout(() => {
          const Toast = Swal.mixin({
            toast: true,
            position: "bottom",
            iconColor: 'white',
            customClass: {
              popup: 'colored-toast',
            },
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            }
          });
          Toast.fire({
            icon: "success",
            title: "Broadcast Created Successfully"
          });
          setSegmentName('');
          setErrorShow('');
          setSelectedSegmentId([]);
          fetchBroadcastData("0");
          setShowCard(true)
          setShowBroadCast(false)
          setLoading(false)
        }, 1500);
      } else {
        setLoading(false)
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      }
    } catch (error) {
      console.error('Error submitting form data:', error);
    }
  };

  const refreshbroadcast = () => {
    setRefreshBroadcastdata(true);
    fetchBroadcastData("0")
      .then(response => {
        // Handle the returned data here 
        setRefreshBroadcastdata(false);
      })
      .catch(error => {
        // Handle errors here
        console.error("Error fetching data:", error);
      });
  };

  function jsonToExcel(jsonData: any[]) {
    const ws = XLSX.utils.json_to_sheet(jsonData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    return excelBuffer;
  }

  function downloadExcel(data: any[], filename: string) {
    const excelBuffer = jsonToExcel(data);
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    a.href = url;
    a.download = filename;
    a.click();
    URL.revokeObjectURL(url);
  }

  const handleRecipientData = async (broadcast_segment_id: any) => {
    setIsLoading(true);
    setReceivetModel(true)
    try {
      const response = await whatsapp_RECIPIENTS_GET(broadcast_segment_id);
      setReceiveData(response.data.broadcast_segment_details)
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  const handleDelete = async (order_contact_number: string) => {
    try {
      setReceiveData(prevData =>
        prevData.map(segment => {
          if (typeof segment.segment_customers === 'string') {
            // Parse the string to JSON if it's a string
            const parsedCustomers = JSON.parse(segment.segment_customers);
            // Filter out the customer with the matching order_contact_number
            const updatedCustomers = parsedCustomers.filter((customer: any) =>
              customer.order_contact_number !== order_contact_number);
            return {
              ...segment,
              segment_customers: JSON.stringify(updatedCustomers)
            };
          }
          return segment;
        })
      );

      // const segmentRecipiantsData = receiveData[0];
      // const response = await Segment_Recipients_Update(
      //   segmentRecipiantsData.segment_id,
      //   segmentRecipiantsData.segment_customers,
      //   details?.id || ''
      // );
      // if (response.data.message === 'success') {
      //   setLoading(false);
      //   fetchSegmentsData("0");
      //   Swal.fire(
      //     'Deleted!',
      //     'Customer has been deleted.',
      //     'success'
      //   );
      // } else {
      //   setLoading(false);
      //   Swal.fire({
      //     icon: "error",
      //     title: "Oops...",
      //     text: "Something went wrong!",
      //   });
      // }
    } catch (error) {
      console.error('Error deleting customer:', error);
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong while deleting the customer!",
      });
    }
  };

  const handleSegmentUpdate = async () => {
    setLoading(true);
    try {
      const segmentRecipiantsData = receiveData[0];
      const response = await Segment_Recipients_Update(
        segmentRecipiantsData.segment_id,
        segmentRecipiantsData.segment_customers,
        userID
      );
      if (response.data.message === 'success') {
        setLoading(false);
        fetchSegmentsData("0");
        setReceivetModel(false)
        Swal.fire(
          'Deleted!',
          'Customer has been deleted.',
          'success'
        );
      } else {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      }
    } catch (error) {
      console.error('Error deleting customer:', error);
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong while deleting the customer!",
      });
    }
  }


  const handleViewReportData = async (broadcast_id: any, cust_id: string) => {
    setIsLoading(true);
    setReportModel(true)
    try {
      const response = await whatsapp_broadcast_download(broadcast_id, cust_id);
      setIsLoading(false);
      const jsonData = response.data.broadcast_log_data;
      setReportData(jsonData)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleDownloadData = async (broadcast_id: any, cust_id: string) => {
    try {
      const response = await whatsapp_broadcast_download(broadcast_id, cust_id);
      const jsonData = response.data.broadcast_log_data;
      downloadExcel(jsonData, 'BroadcastData.xlsx');
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const SegmentDelete = (segment_del_id: string, contact_cust_id: any) => {
    const handleDeleteEvent = async () => {
      try {
        const response = await Segment_DELETE_data(segment_del_id, contact_cust_id);
        const filterdata = segmentData.filter(
          (item) => item.segment_id !== segment_del_id
        );
        setSegentData(filterdata);
      } catch (error) {
        console.error("Error deleting event:", error);
      }
    };
    handleDeleteEvent();
  };

  const handleDeleteConfirmation = (contact_del_id: string, contact_cust_id: any) => {
    Swal.fire({
      title: 'Are you sure!',
      text: "Delete this segment..?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true,
      customClass: {
        confirmButton: 'btn btn-success', // Success color for "Yes, delete it!"
        cancelButton: 'btn btn-danger',   // Danger color for "No, cancel"
      },
    }).then((result) => {
      if (result.isConfirmed) {
        // Call your delete function here
        SegmentDelete(contact_del_id, contact_cust_id);
        Swal.fire(
          'Deleted!',
          'Your segment has been deleted.',
          'success'
        )
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        Swal.fire(
          'Cancelled',
          'Canceling will not save any changes :)',
          'error'
        )
      }
    });
  };

  return (
    <>
      {showCard && (
        <>
          <div className='card'>
            <div className='card-header border-0'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-3 mb-1'>Broadcast</span>
              </h3>
              <div className='card-toolbar'>
                <button className='btn btn-sm btn-primary' onClick={handleAddBroadcastClick}>
                  <KTIcon iconName='plus' className='fs-2' />
                  Add Broadcast
                </button>
              </div>
            </div>
          </div>
          <div className='card p-8 mt-5'>

            {isLoading ? (
              <Loader />
            ) : (recentBroadcast.length > 0 ? (
              <>
                <div className="d-flex justify-content-between mb-5">
                  <ul className="nav nav-tabs nav-line-tabs nav-line-tabs-2x fs-6">
                    <li className="nav-item">
                      <a className="nav-link active" data-bs-toggle="tab" href="#kt_tab_pane_4">
                        Recent
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" data-bs-toggle="tab" href="#kt_tab_pane_5">
                        History
                      </a>
                    </li>
                  </ul>

                  <button className="btn btn-primary btn-sm" onClick={refreshbroadcast}>{!refreshbroadcastdata ? (
                    <span><i className="bi bi-arrow-clockwise"></i> Refresh Data</span>
                  ) : (
                    <span> <i className="spinner-border spinner-border-sm"></i> Loading....</span>
                  )} </button>
                </div>
                <div className={`tab-content ${refreshbroadcastdata ? 'd opacity-50' : ''}`} id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="kt_tab_pane_4"
                    role="tabpanel">
                    <div className='table-responsive bg-white p-5'>
                      <table className='table align-middle gs-0 gy-4'>
                        <thead>
                          <tr className='fw-bold fs-6 bg-light-primary'>
                            <th className='ps-5'>Broadcast Name</th>
                            <th>SCHEDULED AT</th>
                            <th className='w-100px'>SENT</th>
                            <th className='w-100px'>DELIVERED</th>
                            <th className='w-100px'>READ</th>
                            <th>RECIPIENTS</th>
                            <th>FAILED</th>
                            <th>STATUS</th>
                            <th>REPORT</th>
                          </tr>
                        </thead>
                        <tbody>
                          {recentBroadcast.map((broadcast_item, index) => (
                            <tr key={index}>
                              <td className='text-dark fw-bold text-hover-primary fs-6 ps-5'>
                                {broadcast_item.broadcast_name}
                              </td>
                              <td className='text-dark fw-bold text-hover-primary fs-6'>
                                {broadcast_item.broadcast_schedule_date}   {broadcast_item.broadcast_schedule_time}
                              </td>

                              <td className='text-end'>
                                <div className='d-flex flex-column w-100 me-2'>
                                  <div className='d-flex flex-stack mb-2'>
                                    <span className='text-muted me-2 fs-7 fw-semibold'>{broadcast_item.broadcast_message_sent_count}%</span>
                                  </div>
                                  <div className='progress h-6px w-100'>
                                    <div
                                      className='progress-bar bg-success'
                                      role='progressbar'
                                      style={{ width: broadcast_item.broadcast_message_sent_count + '0%' }}
                                    ></div>
                                  </div>
                                </div>
                              </td>
                              <td className='text-end'>
                                <div className='d-flex flex-column w-100 me-2'>
                                  <div className='d-flex flex-stack mb-2'>
                                    <span className='text-muted me-2 fs-7 fw-semibold'>{broadcast_item.broadcast_message_delivered_count}%</span>
                                  </div>
                                  <div className='progress h-6px w-100'>
                                    <div
                                      className='progress-bar bg-success'
                                      role='progressbar'
                                      style={{ width: broadcast_item.broadcast_message_delivered_count + '0%' }}
                                    ></div>
                                  </div>
                                </div>
                              </td>
                              <td className='text-end'>
                                <div className='d-flex flex-column w-100 me-2'>
                                  <div className='d-flex flex-stack mb-2'>
                                    <span className='text-muted me-2 fs-7 fw-semibold'>{broadcast_item.broadcast_message_read_count}%</span>
                                  </div>
                                  <div className='progress h-6px w-100'>
                                    <div
                                      className='progress-bar bg-success'
                                      role='progressbar'
                                      style={{ width: broadcast_item.broadcast_message_read_count + '0%' }}
                                    ></div>
                                  </div>
                                </div>
                              </td>
                              <td className='text-dark fw-bold text-hover-primary fs-6 text-center'>
                                {broadcast_item?.broadcast_recipients}
                              </td>
                              <td className='text-dark fw-bold text-hover-primary fs-6 text-center'>
                                {broadcast_item.broadcast_failed_receipients ? broadcast_item.broadcast_failed_receipients : "-"}
                              </td>
                              <td className='text-dark text-hover-primary fs-6'>
                                <span className='badge badge-light-success badge-pill p-2'><i className="bi bi-check2-circle me-1"></i> Complete</span>
                              </td>
                              <td style={{ paddingTop: "10px" }}>
                                <div className="dropdown">
                                  <button className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="bi bi-three-dots fs-5"></i>
                                  </button>
                                  <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                                    <li>
                                      <button
                                        className="dropdown-item"
                                        type="button"
                                        onClick={() => handleViewReportData(broadcast_item.broadcast_id, customerId)}
                                      >
                                        View
                                      </button>
                                    </li>
                                    <li>
                                      <button
                                        className="dropdown-item"
                                        type="button"
                                        onClick={() => handleDownloadData(broadcast_item.broadcast_id, customerId)}
                                      >
                                        Download
                                      </button>
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                          ))
                          }
                        </tbody>
                      </table>
                      <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                      />
                    </div>
                  </div>
                  <div className="tab-pane fade" id="kt_tab_pane_5" role="tabpanel">
                    <div className='table-responsive bg-white p-5'>
                      <table className='table align-middle gs-0 gy-4'>
                        <thead>
                          <tr className='fw-bold fs-6 bg-light-primary'>
                            <th className='ps-5'>Broadcast Name</th>
                            <th>SCHEDULED AT</th>
                            <th>SENT</th>
                            <th>DELIVERED</th>
                            <th>READ</th>
                            <th>RECIPIENTS</th>
                            <th>FAILED</th>
                            <th>ACTION</th>
                            <th>STATUS</th>
                          </tr>
                        </thead>
                        <tbody>

                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </>
            ) :
              <div className="d-flex justify-content-center p-10">
                <div>
                  <img src={toAbsoluteUrl('/media/stock/coming_soon.png')} className='w-350px' alt='coming soon' />
                  <h3 className="text-center">No broadcast found</h3>
                </div>
              </div>
            )}
          </div>
        </>
      )}

      {showTabs && (
        <>
          <Segment handleBackButtonClick={handleBackButtonClick} isLoading={isLoading} setLoading={setLoading}
            setSelectedCheckboxes={setSelectedCheckboxes} setSelectedSegmentId={setSelectedSegmentId} setSelectedNames={setSelectedNames}
            selectedCheckboxes={selectedCheckboxes} handleDeleteConfirmation={handleDeleteConfirmation} customerId={customerId} OpenFilterModel={OpenFilterModel}
            segmentData={segmentData} isApplied={isApplied} customers={customers} setCustomers={setCustomers} segmentName={segmentName} setSegmentName={setSegmentName} setErrorShow={setErrorShow}
            userID={userID} fetchSegmentsData={fetchSegmentsData} loading={loading} errorShow={errorShow} handleRecipientData={handleRecipientData}
          />

          {selectedCheckboxes.length >= 1 && (
            <div className="sticky-content">
              <div className="d-flex align-items-center justify-content-between bg-primary rounded-pill p-5">
                <h4 className="ms-3 text-light">
                  <span></span> {`${selectedCheckboxes.length} Segment${selectedCheckboxes.length > 1 ? "'s" : ""} Selected`}
                </h4>
                <div className="d-flex align-items-center me-2">
                  <button className="btn btn-sm btn-light" type="button" onClick={handleContinueButtonClick}>
                    Continue
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}

      <div className={`modal${showModal ? ' show' : ''}`} role="dialog" style={{ display: showModal ? 'block' : 'none' }}>
        <div className="modal-dialog  modal-dialog-centered" role="document">
          <SegmentFilterForm setShowModal={setShowModal} setIsApplied={setIsApplied} setCustomers={setCustomers} />
        </div>
      </div>

      {showAdditionalContent && (
        <>
          <Template apiData={apiData} handleSelectTemplate={handleSelectTemplate} handleBackButtonTemplate={handleBackButtonTemplate} />
        </>
      )
      }

      {showPopup && selectedTemplate && (
        <div className="modal" role="dialog" style={{ display: 'block' }}>
          <div className="modal-dialog modal-lg modal-dialog-scrollable" role="document">
            <TemplateModal selectedTemplate={selectedTemplate} setShowPopup={setShowPopup} errorShow={errorShow} setErrorShow={setErrorShow} uploadedVideo={uploadedVideo} setUploadedVideo={setUploadedVideo}
              frontImageError={frontImageError} setFrontImageError={setFrontImageError} setPdfUpload={setPdfUpload} pdfUpload={pdfUpload} setImageUpload={setImageUpload} imageUpload={imageUpload}
              setSelectedVariables={setSelectedVariables} setVariableError={setVariableError} variableError={variableError} handleContinueTemplate={handleContinueTemplate} loading={loading}
            />
          </div>
        </div>
      )}

      {showBroadCast && (
        <>
          <BroadcastForm handleBackButtonTemplate={handleBackButtonTemplate} loading={loading} handleSubmitBroadCast={handleSubmitBroadCast}
            personNames={personNames} segmentCustomers={segmentCustomers} selectedTemplate={selectedTemplate} selectedVariables={selectedVariables}
            segmentImage={segmentImage}
          />
        </>
      )}

      {reportModel && (
        <div className="modal" role="dialog" style={{ display: 'block' }}>
          <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">View Broadcast Report :</h5>
                <button type="button" className="btn-close" onClick={() => setReportModel(false)} aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className='d-flex justify-content-between align-items-baseline'>
                  <div>
                    <h6>Total Contact: <span className='badge badge-success p-2'>{reportData.length}</span></h6>
                  </div>
                  <div className="float-end">
                    <div className="input-group">
                      <input type="search" placeholder='Search Number...' className="form-control w-50"
                        value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)}
                      />
                      <button type="button" className="btn btn-primary">
                        <i className="fas fa-search"></i>
                      </button>
                    </div>
                  </div>
                </div>
                {isLoading ? (
                  <Loader />
                ) : (
                  <>
                    <div className='table-responsive p-5'>
                      <table className='table align-middle gs-0 gy-4'>
                        <thead>
                          <tr className='fw-bold fs-6 bg-light-primary'>
                            <th className='ps-5'>Contact number</th>
                            <th>Created on</th>
                            <th className=''>Delivered on</th>
                            <th className=''>Read on</th>
                            <th className=''>Sent on</th>
                          </tr>
                        </thead>
                        <tbody>
                          {ReportLists.map((data, index) => (
                            <>
                              <tr key={index}>
                                <td className='text-dark fw-bold text-hover-primary fs-6 ps-5'>
                                  {data?.contact_number}
                                </td>
                                <td className='text-dark fw-bold text-hover-primary fs-6'>
                                  {data?.created_on}
                                </td>
                                <td className='text-dark fw-bold text-hover-primary fs-6'>
                                  {data?.delivered_on}
                                </td>
                                <td className='text-dark fw-bold text-hover-primary fs-6'>
                                  {data?.read_on}
                                </td>
                                <td className='text-dark fw-bold text-hover-primary fs-6'>
                                  {data?.sent_on}
                                </td>
                              </tr>
                            </>
                          ))}
                        </tbody>
                      </table>
                      {searchQuery ? (
                        <Pagination currentPage={reportTotalData} totalPages={filterTotalPage} handlePageChange={handleReportPageChange} />
                      ) : (
                        <Pagination currentPage={reportTotalData} totalPages={totalReportPages} handlePageChange={handleReportPageChange} />
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {receiveModel && (
        <div className="modal" role="dialog" style={{ display: 'block' }}>
          <div className="modal-dialog  modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">View Recipients :</h5>
                <button type="button" className="btn-close" onClick={() => setReceivetModel(false)} aria-label="Close"></button>
              </div>
              <div className="modal-body form_content">
                <div className='d-flex justify-content-between mb-5'>
                  <h5>Total Customer: {
                    receiveData.reduce((total, segment) =>
                      total + (Array.isArray(segment.segment_customers)
                        ? segment.segment_customers.length
                        : (typeof segment.segment_customers === 'string'
                          ? JSON.parse(segment.segment_customers).length
                          : 0)
                      ), 0)
                  }</h5>
                  {/* <div className='ps-4'>
                    <button type="button" className="btn btn-sm btn-primary" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                      <i className="fas fa-search"></i>
                    </button>
                  </div> */}
                </div>
                {/* <div className="collapse w-100" id="collapseExample">
                  <div className="d-flex align-items-center position-relative">
                    <div className="input-group">
                      <input type="search" placeholder='Search Name...'
                        value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)}
                        className="form-control w-50" />
                      <button type="button" className="btn btn-primary">
                        <i className="fas fa-search"></i>
                      </button>
                    </div>
                  </div>
                </div> */}
                {isLoading ? (
                  <Loader />
                ) : (
                  <>
                    <div className='table-responsive'>
                      <table className='table align-middle'>
                        <thead>
                          <tr className='fw-bold fs-6 bg-light-primary'>
                            <th className='ps-5'>Customer Name</th>
                            <th>Remove</th>
                          </tr>
                        </thead>
                        <tbody>
                          {ReceiveLists.slice(ReceiveIndex, ReceiveIndex + ReceiveDataPerPage).map((customer, index) => (
                            <tr key={`customer-${index}`}>
                              <td className='text-dark fw-bold text-hover-primary fs-6 ps-5'>
                                <div>{customer.order_person_name}</div>
                                <div>{customer.order_contact_number}</div>
                              </td>
                              <td>
                                <button className='btn btn-sm btn-light-danger' onClick={() => handleDelete(customer.order_contact_number)}><KTIcon iconName='cross-square' className='fs-2x' /></button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <Pagination
                        currentPage={receiveTotalData}
                        totalPages={totalReceivePages}
                        handlePageChange={(page: number) => setReceiveTotalData(page)}
                      />
                    </div>
                  </>
                )}
              </div>
              <div className="modal-footer">
                <div className='d-flex justify-content-end'>
                  <button type='submit' className='btn btn-primary' disabled={loading} onClick={handleSegmentUpdate}>
                    {!loading && 'Submit'}
                    {loading && (
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Broadcast;