import React, { useState, useEffect } from 'react';
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useParams } from 'react-router-dom';
import {orders_alert,SuccessOrders } from '../core/_requests'
import { useNavigate,Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
interface Orderitems {
    order_id: string;
    order_item_name: string;
    order_item_qty: string;
    order_item_price: string;
    order_item_inline_total: string;
}

interface OrderDetails {
    order_id: string;
    contact_number: string;
    contact_person: string;
    order_total: number;
    order_status: string;
    order_on: string;
    special_instructions: string;
    message: string;
    order_payment: string;
    order_items: Orderitems[];
    order_sub_total: number;
    gst:string;
}

const SuccessPage = () => {
    const navigate = useNavigate();
    const { order_id } = useParams<{ order_id: string }>();

    console.log(order_id);
    const [viewPerson, setViewPerson] = useState<OrderDetails>()
    const location = useLocation();
    const [isloading, setIsLoading] = useState(true);
    const [razorpayPaymentOrderId, setRazorpayPaymentId] = useState<string | null>(null);
    const [razorpay_payment_id, setrazorpay_payment_id] = useState<string | null>(null);
    const [razorpay_payment_link_status, setrazorpay_payment_link_status] = useState<string | null>(null);

    useEffect(() => {
      const searchParams = new URLSearchParams(location.search);
      const paymentId = searchParams.get('razorpay_payment_link_reference_id');

      if (paymentId) {
        setRazorpayPaymentId(paymentId);
        setrazorpay_payment_id(searchParams.get('razorpay_payment_link_id'));
        setrazorpay_payment_link_status(searchParams.get('razorpay_payment_link_status'));
      }
    }, [location.search]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (razorpayPaymentOrderId) {
                    const order_mode = 2;
                    const response = await SuccessOrders(razorpayPaymentOrderId,order_mode,razorpay_payment_id,razorpay_payment_link_status);
                    const response1 = await orders_alert(razorpayPaymentOrderId);
                    setViewPerson(response.data);
                     //console.log(response.data.contact_number)
                     //window.location.href = "https://api.whatsapp.com/send/?phone=918148704397&text&type=phone_number&app_absent=0";
                     //console.log(response1);

                     setIsLoading(false)
                } else {
                    // Redirect to the home page when order_id is null
                    ///navigate('/');
                }
            } catch (error) {
                console.error('API Error:', error);
            }
        };
        fetchData();
    }, [razorpayPaymentOrderId, navigate]);
    const GST_percentage = 18; // GST percentage
    // Assuming viewPerson?.order_total is a number
   // const orderTotal = viewPerson?.order_total || 0;
   const orderTotal = Math.round(viewPerson?.order_total || 0);
   const GST_amount = (orderTotal * GST_percentage) / 100;
   const GRAND_TOTAL = orderTotal + GST_amount;

    return (
        <>
            <div className='d-flex justify-content-center align-items-center mt-lg-10 mb-lg-20'>
                <div className='card bg-light-success shadow-none w-lg-500px'>
                    {isloading? (
                        <div className='vh-100 justify-content-center text-center align-self-center mt-20 mb-20 w-500px' style={{top:'50'}}>
                             <i className="bi bi-receipt-cutoff text-success" style={{fontSize:'60px'}}></i>
                               <div className='mt-5'>
                               <span className='spinner-border spinner-border-lg align-middle fs-2 text-primary' ></span>
                               <span className='ms-2'>Please Wait....</span>
                               <h4 className='mt-5'>We are fetching your order data</h4>
                                </div>
                        </div>
                    ) : (
                    <div className='card-body'>
                        <div className="d-flex justify-content-center mb-5">
                            <img src={toAbsoluteUrl('/media/stock/check.png')} className='w-50px' />
                        </div>
                        <h1 className="text-center mt-2">Successfully Order Placed</h1>
                        <h4 className="text-center">Order Id : {viewPerson?.order_id}</h4>
                        <h4 className="text-center">Name : {viewPerson?.contact_person}</h4>
                        <h4 className="text-center">Mobile Number : {viewPerson?.contact_number}</h4>
                        <h4 className="text-center">Order Date : {viewPerson?.order_on}</h4>
                        <h4 className="text-center">Order Status :
                             {viewPerson?.order_status === "0" ? (
                                <span className='badge bg-danger text-white'>Pending</span>
                            ) : viewPerson?.order_status === "1" ? (
                                <span className='badge bg-warning text-white'>Processing</span>
                            ) : viewPerson?.order_status === "3" ? (
                                <span className="badge bg-success text-white">Completed</span>
                            ) : viewPerson?.order_status === "2" ? (
                                <span className='badge bg-info text-white'>Rejected</span>
                            ) : null}
                        </h4>
                        <h4 className="text-center">Payment Status :
                             {viewPerson?.order_payment === "1" ? (
                                 <span className='badge bg-success text-white'>Paid</span>
                            ) :(
                                 <span className='badge bg-danger text-white'>Not Paid</span>
                            )}
                        </h4>
                        <div className='table-responsive'>
                            <hr></hr>
                            <h5 className="mt-5 text-dark">ORDER DETAILS</h5>
                            <table className='table align-middle p-2 gy-4 table table-bordered table-sm'>
                                <thead className='bg-dark text-light fw-bold  text-hover-primary fs-6'>
                                <th className='ps-2 p-2'>Item Details</th>
                                <th className='ps-2 p-2'>Total</th>
                                </thead>

                                <tbody>
                                    {viewPerson?.order_items.map((item, index) => (
                                        <tr key={index}>
                         <td className='text-dark fw-bold text-hover-primary fs-6 p-2' style={{width:'80%'}}>
                                                {item.order_item_name} x {item.order_item_qty} <br></br>
                                                <small className='fs-9'>Item Price: ₹{item.order_item_price}</small>
                                            </td>

                                            <td className='text-dark fw-bold text-hover-primary fs-6 p-2' style={{width:'20%'}}>
                                            ₹{item.order_item_inline_total}
                                            </td>
                                        </tr>
                                    ))
                                    }
                                </tbody>
                            </table>
                            <hr />
                            <br />
                            {/* <h2 className='fw-bold text-dark align-right'>Total : {viewPerson?.order_total}</h2> */}
                        </div>
                        <h4 className='fw-bold text-dark mb-2'>Sub Total : ₹{viewPerson?.order_sub_total}</h4>
                        <h4 className='fw-bold text-dark mb-2'>GST (18%): ₹{viewPerson?.gst}</h4>
                        <h2 className='fw-bold text-dark mb-10'>Grand Total : ₹{viewPerson?.order_total}</h2>
                        <div className="d-flex justify-content-center">
                            {/* <Link to='/'>
                                <button className='btn btn-sm btn-success p-5'>Back to Home</button>
                            </Link> */}

                            <a href='https://wa.me/918148704397' className='btn btn-sm btn-success p-3'>Back to Whatsapp</a>
                        </div>
                    </div>
                     ) }
                </div>

            </div>
        </>
    )
}

export default SuccessPage