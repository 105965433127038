import React, { useCallback, useState } from 'react'
import { handleImageDrop } from '../../../../../pages/CompressImage';
import { useDropzone } from "react-dropzone";

const TemplateModal = ({ selectedTemplate, setShowPopup, errorShow,setErrorShow,uploadedVideo,setUploadedVideo,frontImageError,setFrontImageError, 
    setPdfUpload,pdfUpload,setImageUpload,imageUpload,setSelectedVariables,setVariableError,variableError,handleContinueTemplate,loading
}: any) => {
    const [pdfsizeError, setPdfsizeError] = useState('');

    const onDrop = useCallback((acceptedFiles: any) => {
        const videoFile = acceptedFiles[0];
        if (videoFile && videoFile.size <= 5 * 1024 * 1024) { // Check if file size is less than or equal to 2MB
            setUploadedVideo(videoFile);
            setErrorShow('');
            setFrontImageError('')
        } else {
            setErrorShow('File size must be 5MB or less.');
            setUploadedVideo(null);
        }
    }, []);

    const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: { 'video/mp4': [] }, multiple: false });

    const handleDrop = (acceptedFiles: File[]) => {
        if (acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
            if (file.size <= 2 * 1024 * 1024) { // Checking if file size is less than 2MB
                setPdfUpload([file]);
                setPdfsizeError('');
                setFrontImageError('')
            } else {
                setPdfsizeError('PDF file size should be less than 2MB.');
            }
        }
    };

    const { getRootProps: getRootProps1, getInputProps: getInputProps1 } = useDropzone({
        onDrop: handleDrop,
        accept: {
            'application/pdf': [],
        },
        multiple: false
    });

    const { getRootProps: getRootProps2, getInputProps: getInputProps2 } = useDropzone({
        onDrop: acceptedFiles => handleImageDrop(acceptedFiles, setImageUpload),
        accept: {
            'image/jpeg': [],
            'image/png': [],
        }, multiple: false,
    });

    const getVariableCount = (text: string) => {
        const count = (text.match(/{{\d+}}/g) || []).length; 
        return count;
      };
    
      const renderVariableSelects = () => {
        const bodyText = selectedTemplate?.components.find((component:any) => component.type === 'BODY')?.text || ''; 
        const TotalvariableCount = getVariableCount(bodyText); 
        const selects = [];
    
        for (let i = 1; i <= TotalvariableCount; i++) {
          selects.push(
            <select
              key={i}
              className="form-select form-select-white mb-5"
              onChange={(event) => handleVariableSelectChange(event, i)}
              aria-label={`Select variable ${i}`}
              style={{ width: '30%' }}
            >
              <option>select variable {i}</option>
              <option value="order_person_name">Name</option>
              <option value="order_contact_number">Phone Number</option>
            </select>
          );
        }
    
        return selects;
      };
    
      const handleVariableSelectChange = (event: React.ChangeEvent<HTMLSelectElement>, index: number) => {
        const selectedVariable = event.target.value;
    
        if (selectedVariable) {
          setSelectedVariables((prevSelectedVariables:any) => {
            const updatedVariables = [...prevSelectedVariables];
            updatedVariables[index - 1] = selectedVariable; // Adjust index to be zero-based
            return updatedVariables;
          });
        } else {
          setSelectedVariables((prevSelectedVariables:any) => {
            const updatedVariables = [...prevSelectedVariables];
            updatedVariables[index - 1] = ''; // Clear the value if deselected
            return updatedVariables;
          });
        }
        setVariableError('');
      };
    return (
        <>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">{selectedTemplate.name}</h5>
                    <button type="button" className="btn-close" aria-label="Close" onClick={() => setShowPopup(false)}></button>
                </div>
                <div className="modal-body">
                    <p>
                        {selectedTemplate.components.map((component: any, index: any) => (
                            <>
                                <p className='badge badge-danger text-white'>{component?.format}</p>
                                <div key={index} style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: component.text }} />
                            </>
                        ))}
                    </p>
                    <div className='separator border border-secondary'></div>
                    <div className='mt-10'>
                        {selectedTemplate.components.map((component: any, index: any) => (
                            <>
                                <p key={index}>
                                    {component?.format === "VIDEO" ? (
                                        <>
                                            <label className="form-label mt-3 mb-3 required" htmlFor="upload_file">Video Upload</label>
                                            {errorShow && <p className="text-danger">{errorShow}</p>}
                                            <div className='d-flex'>
                                                <div {...getRootProps({ className: "dropzone" })} >
                                                    <input {...getInputProps()} />
                                                    <p>Drag 'n' drop a video file, or click to select a file</p>
                                                </div>
                                                <div className='ms-5'>
                                                    {uploadedVideo && (
                                                        <>
                                                            <video controls className='w-250px'>
                                                                <source src={URL.createObjectURL(uploadedVideo)} />
                                                            </video>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                            {frontImageError && <div className="text-danger mt-2">Please upload a video.</div>}
                                        </>
                                    ) : component?.format === "IMAGE" ? (
                                        <>
                                            <label className="form-label mt-3 mb-3 required" htmlFor="upload_file">Image Upload</label>
                                            <div className='d-flex'>
                                                <div {...getRootProps2({ className: "dropzone" })} >
                                                    <input className="input-zone" {...getInputProps2()} name='back_image' />
                                                    <div className="text-center">
                                                        <p className="dropzone-content">
                                                            Drag’n’drop some files here, or click to select files
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className='ms-5'>
                                                    {imageUpload.map((file:any) => (
                                                        <div key={file.name} style={{ margin: '10px' }}>
                                                            <img
                                                                src={typeof file === 'string' ? file : URL.createObjectURL(file)}
                                                                alt={file.name}
                                                                className='w-250px'
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            {frontImageError && <div className="text-danger mt-2">Please upload a image.</div>}
                                        </>
                                    ) : component?.format === "DOCUMENT" ? (
                                        <>
                                            <label className="form-label mt-3 mb-3 required" htmlFor="upload_file">Document Upload</label>
                                            {pdfsizeError && <div className="text-danger mb-2">{pdfsizeError}</div>}
                                            <div {...getRootProps1({ className: "dropzone" })}>
                                                <input type="file" id="upload_file" {...getInputProps1()} accept=".png,.jpeg" />
                                                <div className="text-center">
                                                    <p className="dropzone-content">
                                                        Drag’n’drop or click to select files
                                                    </p>
                                                </div>
                                            </div>
                                            {pdfUpload.map((file:any) => (
                                                <div key={file.name} className='mt-5'>
                                                    <p className='badge bg-danger text-white p-3'> {file.name}</p>
                                                </div>
                                            ))}
                                            {frontImageError && <div className="text-danger mt-2">Please upload a pdf.</div>}
                                        </>
                                    ) : ""}
                                </p>
                            </>
                        ))}
                        <label className="required form-label">Variables</label>
                        {renderVariableSelects()}
                        {variableError && <div className="text-danger mt-2">{variableError}</div>}
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary me-2" onClick={() => setShowPopup(false)}>
                        Cancel
                    </button>
                    <div className='d-flex justify-content-end'>
                        <button className="btn btn-primary" type="submit" onClick={handleContinueTemplate} disabled={loading}>
                            {!loading ? 'Continue' : (
                                <span className='indicator-progress' style={{ display: 'inline-block' }}>
                                    Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TemplateModal