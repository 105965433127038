import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers';


const Integration = () => {

    return (
        <>
            <div className='row g-6 g-xl-9'>
                <div className='col-md-6 col-xxl-3'>
                    <div className='card'>
                        <div className='card-body d-flex flex-center flex-column p-9'>
                            <div className='mb-5'>
                                <div className='symbol symbol-75px'>
                                    <img alt='Pic' src={toAbsoluteUrl('/media/whatsapp/razorpay.png')} />
                                </div>
                            </div>
                            <div className='fs-4 text-gray-800 text-hover-primary fw-bolder mb-0'>
                                Razorpay
                            </div>
                            <div className='fw-bold text-gray-400 mb-6'>Active</div>
                            <Link to='/integration/razorpay'>
                                <button className='btn btn-sm btn-primary fw-bolder'>
                                    Configure
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>

                <div className='col-md-6 col-xxl-3'>
                    <div className='card'>
                        <div className='card-body d-flex flex-center flex-column p-9'>
                            <div className='mb-5'>
                                <div className='symbol symbol-75px'>
                                    <img alt='Pic' src={toAbsoluteUrl('/media/whatsapp/gmail.svg')} />
                                </div>
                            </div>
                            <span className='fs-4 text-gray-800 text-hover-primary fw-bolder mb-0'>
                                Email
                            </span>
                            <div className='fw-bold text-gray-400 mb-6'>In-active</div>
                            {/* <Link to='/integration/email'> */}
                            <button className='btn btn-sm btn-primary fw-bolder' disabled>
                                Coming Soon
                            </button>
                            {/* </Link> */}
                        </div>
                    </div>
                </div>

                <div className='col-md-6 col-xxl-3'>
                    <div className='card'>
                        <div className='card-body d-flex flex-center flex-column p-9'>
                            <div className='mb-5'>
                                <div className='symbol symbol-75px'>
                                    <img alt='Pic' src={toAbsoluteUrl('/media/whatsapp/sms.png')} />
                                </div>
                            </div>
                            <div className='fs-4 text-gray-800 text-hover-primary fw-bolder mb-0'>
                                SMS
                            </div>
                            <div className='fw-bold text-gray-400 mb-6'>In-active</div>
                            {/* <Link to='/integration/sms'> */}
                            <button className='btn btn-sm btn-primary fw-bolder' disabled>
                                Coming Soon
                            </button>
                            {/* </Link> */}
                        </div>
                    </div>
                </div>

                <div className='col-md-6 col-xxl-3'>
                    {/* <div className='card'>
            <div className='card-body d-flex flex-center flex-column p-9'>
              <div className='mb-5'>
                <div className='symbol symbol-75px'>
                  <img alt='Pic' src={toAbsoluteUrl('/media/whatsapp/woocommerce.svg')} />
                </div>
              </div>
              <a className='fs-4 text-gray-800 text-hover-primary fw-bolder mb-0'>
                Woocommerce
              </a>
              <div className='fw-bold text-gray-400 mb-6'>In-active</div>
              <Link to='/integration/woocommerce'>
                <button className='btn btn-sm btn-primary fw-bolder'>
                  Configure
                </button>
              </Link>
            </div>
          </div> */}
                </div>

            </div>
        </>
    )
}
export default Integration