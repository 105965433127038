import React, { useState } from 'react'; 
import EmojiPicker from 'emoji-picker-react';
import { KTIcon } from '../../_metronic/helpers'

const EmojiPickerComponent = ({ setTemplateBody }: any) => {
    const [chosenEmoji, setChosenEmoji] = useState<any>(null);

    const toggleEmojiPicker = () => {
        setChosenEmoji(chosenEmoji ? null : true);
    };

    const onEmojiClick = (emojiObject: any) => {
        setChosenEmoji(emojiObject); 
        setTemplateBody((prevBody: any) => `${prevBody}${emojiObject.emoji}`);
    };

    return (
        <>
            {chosenEmoji ?
                <div className="symbol symbol-circle symbol-25px cursor-pointer" onClick={toggleEmojiPicker}>
                    <div className="symbol-label">
                        <KTIcon iconName='cross-square' className='fs-1 text-danger' />
                    </div>
                </div> :
                <i
                    className="bi bi-emoji-smile fs-1 text-dark fw-bold me-5 cursor-pointer"
                    onClick={toggleEmojiPicker}
                ></i>
            }
            
            {chosenEmoji && (
                <div className="emoji-picker mt-5">
                    <EmojiPicker onEmojiClick={onEmojiClick} />
                </div>
            )}
        </>
    );
};

export default EmojiPickerComponent;
