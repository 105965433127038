import React, { useState, useEffect } from 'react';
import { CONTACTS_GET_DATA, CONTACTS_EDIT_DATA, CONTACTS_DELETE_DATA, Tags_GET_data, Tags_POST_data } from '../../modules/auth/core/_requests';
import { toAbsoluteUrl, KTIcon } from '../../../_metronic/helpers'
import Swal from 'sweetalert2';
import PaginationNew from '../PaginationNew';
import Loader from '../Loader';
import CustomerForm from './ContactForm';
import ContactEditForm from './ContactEditForm';
import ContactImport from './ContactImport';
import { ContactDetails } from '../Interface';
import { useAuth } from '../../modules/auth';
import { Toaster } from 'react-hot-toast';

const Contact = () => {
    const { currentUser } = useAuth()
    const [userRole] = useState(currentUser?.user_role || '')
    const [Login_user_id] = useState(currentUser?.user_id || '')
    const [CustomerId] = useState(currentUser?.cust_id || '')
    const [openModal, setOpenModal] = useState(false);
    const [importModal, setImportModal] = useState(false);
    const [customers, setCustomers] = useState<ContactDetails[]>([]); 
    const [searchQuery, setSearchQuery] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [showPerPage, setShowPerPage] = useState(10);
    const [filteredMembers, setFilteredMembers] = useState<ContactDetails[]>([]);
    const [loadingEdit, setLoadingEdit] = useState(false);
    const [tagsAdd, setTagsAdd] = useState(false);
    const [newTags, setNewTags] = useState('');
    const [tagsError, setTagsError] = useState('');
    const [getTags, setGetTags] = useState<{ tag_id: string; tag_name: string, tag_created_on: string }[]>([]);
    const [tagsSelected, setTagsSelected] = useState<Array<{ value: string; label: string }>>([]);
    const [showEditModal, setShowEditModal] = useState(false)
    const [sortColumn, setSortColumn] = useState<string>('');
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc' | ''>('');
    const [initialEditValues, setInitialEditValues] = useState(
        {
            contact_id_upd: '',
            customer_name_upd: '',
            customer_phone_upd: '',
            customer_email_upd: '',
            customer_address_upd: '',
        }
    )

    const handleAddCustomer = () => {
        setOpenModal(true); 
        setTagsSelected([]); 
    };

    const handleImportCustomer = () => {
        setImportModal(true);
    };

    const handleTags = (selected: any) => {
        setTagsSelected(selected);
    }; 

    const handleAddTags = async (values: any) => {
        try {
            const newTagsValue = values.tags;
            if (!newTagsValue) {
                setTagsError('Tags name cannot be empty');
                return; // Exit early if the tags is empty
            }
            const isOptionAlreadySelected = tagsSelected.some(option =>
                option.value.toLowerCase() === newTagsValue
            );

            if (!isOptionAlreadySelected) {
                const { data: auth } = await Tags_POST_data(
                    newTagsValue,
                    Login_user_id,
                );

                if (auth === 'Already Exist') {
                    setTagsError('Tags already exists. Please select from the dropdown.');
                } else {
                    FetchTagsData("0");
                    setTagsSelected(prevTagsSelected => [
                        ...prevTagsSelected,
                        { value: newTagsValue, label: values.tags }
                    ]);
                    setNewTags('');
                    setTagsError('');
                }
            } else {
                setTagsError('Tags already exists. Please select from the dropdown.');
            }
        } catch (error) {
            console.error('Error adding tags:', error);
        }
    };

    const handleEditCustomers = async (contact_id: any, contact_cust_id: any) => {
        setLoadingEdit(true)
        setShowEditModal(true)
        try {
            const response = await CONTACTS_EDIT_DATA(contact_id,contact_cust_id);
            setLoadingEdit(false)
            const initialData = response.data.contact_info 
            const tagResponse = response.data.contact_info[0].contact_tags;
            const tagData = JSON.parse(tagResponse);
            const formattedArray = tagData.map((item: any) => ({ value: item, label: item }));
            setTagsSelected(formattedArray); 
            setInitialEditValues(
                {
                    contact_id_upd: initialData.length > 0 ? initialData[0].contact_id : '',
                    customer_name_upd: initialData.length > 0 ? initialData[0].contact_name : '',
                    customer_phone_upd: initialData.length > 0 ? initialData[0].contact_mobile : '',
                    customer_email_upd: initialData.length > 0 ? initialData[0].contact_email : '',
                    customer_address_upd: initialData.length > 0 ? initialData[0].contact_address : '',
                }
            )
        } catch (error) {
            console.error('Error fetching members:', error);
        }
    };

    const FetchTagsData = async (all_tags: any) => {
        try {
            const response = await Tags_GET_data(all_tags);
            setGetTags(response.data.tag_details);
        } catch (error) {
            console.error('Error fetching tags:', error);
        }
    };

    useEffect(() => {
        FetchTagsData("0");
    }, []);

    const ContactDelete = (contact_del_id: string, contact_cust_id: any) => {
        const handleDeleteEvent = async () => {
            try {
                const response = await CONTACTS_DELETE_DATA(contact_del_id, contact_cust_id);
                const filterdata = customers.filter(
                    (item) => item.contact_id !== contact_del_id
                );
                setCustomers(filterdata);
            } catch (error) {
                console.error("Error deleting event:", error);
            }
        };
        handleDeleteEvent();
    };

    const handleDeleteConfirmation = (contact_del_id: string, contact_cust_id: any) => {
        Swal.fire({
            title: 'Are you sure!',
            text: "Delete this contact..?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true,
            customClass: {
                confirmButton: 'btn btn-success', // Success color for "Yes, delete it!"
                cancelButton: 'btn btn-danger',   // Danger color for "No, cancel"
            },
        }).then((result) => {
            if (result.isConfirmed) {
                // Call your delete function here
                ContactDelete(contact_del_id, contact_cust_id);
                Swal.fire(
                    'Deleted!',
                    'Your contact has been deleted.',
                    'success'
                )
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire(
                    'Cancelled',
                    'Canceling will not save any changes :)',
                    'error'
                )
            }
        });
    };

    const fetchContactsList = async (get_all_contacts: any, cust_id: any) => {
        try {
            const response = await CONTACTS_GET_DATA(get_all_contacts, cust_id);
            setCustomers(response.data.contact_details);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching members:', error);
        }
    };

    useEffect(() => {
        fetchContactsList("1", CustomerId);
    }, []);

    useEffect(() => {
        const filtered = customers?.filter((app: any) => {
            const appData = `${app.contact_id} ${app.contact_name} ${app.contact_last_name} ${app.contact_mobile} ${app.contact_email} ${app.contact_owner}`.toLowerCase();
            return appData.includes(searchQuery.toLowerCase());
        });
        setFilteredMembers(filtered);
    }, [searchQuery, customers]);

    const sortByColumn = (column: string) => {
        const sortedData = [...customers];

        if (sortColumn === column && (sortOrder === 'asc' || sortOrder === '')) {
            sortedData.sort((a: any, b: any) => a[column].localeCompare(b[column]));
            setSortOrder('desc');
        } else {
            sortedData.sort((a: any, b: any) => b[column].localeCompare(a[column]));
            setSortOrder('asc');
        }
        setSortColumn(column);
        setCustomers(sortedData);
    };

    const handleItemsPerPageChange = (perPage: number) => {
        setShowPerPage(perPage);
        setCurrentPage(1);
    };

    const itemsPerPage = 10;
    const totalItems = customers.length;
    const filterDataTotal = filteredMembers.length
    const totalPages = Math.ceil(totalItems / showPerPage);
    const filterTotalPage = Math.ceil(filterDataTotal / showPerPage);
    const startIndex = (currentPage - 1) * showPerPage;
    const endIndex = startIndex + showPerPage;
    const CustomerDetails = filteredMembers.slice(startIndex, endIndex);

    return (
        <>
  <div className='card'>
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bold fs-3 mb-1'>Customers</span>
                        <span className='mt-1 fw-semibold fs-7'>Total {customers.length} Customers</span>
                    </h3>
                    <div className='card-toolbar'>
                        <div className="d-flex align-items-center me-5">
                            <div className="input-group">
                                <input type="search" placeholder='Search Name & Phone...' className="form-control w-50"
                                    value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)}
                                />
                                <button type="button" className="btn btn-primary">
                                    <i className="fas fa-search"></i>
                                </button>
                            </div>
                        </div>
                        <div className='me-5'>
                            <button className='btn btn-info' onClick={handleImportCustomer}>
                                <KTIcon iconName='file-down' className='fs-2' />
                                Import
                            </button>
                        </div>
                        <div>
                            <button className='btn btn-primary' onClick={handleAddCustomer}>
                                <KTIcon iconName='plus-circle' className='fs-2' />
                                Add Contact
                            </button>
                        </div>
                    </div>
                </div>
                <div className='card-body py-3'>
                {isLoading ? (
                <>
                    <Loader />
                </>
            ) : (CustomerDetails.length > 0 ? (
                <div className='mt-5'>
                    <table className='table align-middle gs-0 gy-4'>
                        <thead className='table_bg_color'>
                            <tr className='fw-bold fs-6 bg-light'>
                                <th className={`ps-5 ${sortColumn === 'contact_id' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'contact_id' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('contact_id')}>
                                    S.No
                                </th>
                                <th className={`${sortColumn === 'contact_name' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'contact_name' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('contact_name')}>
                                    Name
                                </th>
                                <th className={`${sortColumn === 'contact_mobile' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'contact_mobile' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('contact_mobile')}>
                                    Number
                                </th>
                                <th className={`${sortColumn === 'contact_address_line1' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'contact_address_line1' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('contact_address_line1')}>
                                    Email
                                </th> 
                                <th className={`${sortColumn === 'contact_tags' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'contact_tags' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('contact_tags')}>
                                    Tags
                                </th> 
                                <th className={`${sortColumn === 'contact_created_on' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'contact_created_on' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('contact_created_on')}>
                                    Created On
                                </th> 
                                <th className='pe-5'>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {CustomerDetails.map((contact, index) => (
                                <tr key={index} className='border-bottom  border-gray-300'>
                                    <td className='text-dark fw-bold text-hover-primary fs-6 ps-4 text-center'>{contact.contact_id}</td>
                                    <td className='text-dark fw-bold text-hover-primary fs-6'> 
                                        {contact.contact_name}
                                    </td>
                                    <td className='text-dark fw-bold text-hover-primary fs-6'>{contact.contact_mobile ? contact.contact_mobile : "-"}</td>
                                    <td className='text-dark fw-bold text-hover-primary fs-6'>{contact.contact_email ? contact.contact_email : "-"}</td>
 
                                    <td className='text-dark fw-bold text-hover-primary fs-6'>
                                        {typeof contact.contact_tags === 'string' ?
                                            contact.contact_tags.replace(/[\[\]"]+/g, '').split(',').map(tag => (
                                                <span key={tag} className="badge badge-success me-2 p-1">{tag.trim()}</span>
                                            ))
                                            : '-'}
                                    </td> 
                                    <td className='text-dark fw-bold text-hover-primary fs-6'>{contact.contact_created_on} 
                                    </td>
                                     
                                    <td style={{ paddingTop: "10px" }}>
                                        <div className="dropdown">
                                            <button className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i className="bi bi-three-dots fs-5"></i>
                                            </button>
                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                                                <li>
                                                    <button
                                                        className="dropdown-item"
                                                        type="button"
                                                        onClick={() => handleEditCustomers(contact.contact_id,CustomerId)}
                                                    >
                                                        Edit
                                                    </button>
                                                </li>
                                                {userRole != "3" && (
                                                    <>
                                                        <li>
                                                            <button
                                                                className="dropdown-item"
                                                                type="button"
                                                                onClick={() => handleDeleteConfirmation(contact.contact_id,CustomerId)}
                                                            >
                                                                Delete
                                                            </button>
                                                        </li>
                                                    </>
                                                )}
                                            </ul>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {searchQuery ? (
                        <PaginationNew
                            currentPage={currentPage}
                            totalPages={filterTotalPage}
                            itemsPerPage={itemsPerPage}
                            handlePageChange={setCurrentPage}
                            handleItemsPerPageChange={handleItemsPerPageChange}
                        />
                    ) : (
                        <PaginationNew
                            currentPage={currentPage}
                            totalPages={totalPages}
                            itemsPerPage={showPerPage}
                            handlePageChange={setCurrentPage}
                            handleItemsPerPageChange={handleItemsPerPageChange}
                        />
                    )}
                </div >
            ) :
                <div className="d-flex justify-content-center p-10">
                    <div>
                        <img src={toAbsoluteUrl('/media/stock/coming_soon.png')} className='w-350px' />
                        <h3 className="text-center">No contacts found</h3>
                    </div>
                </div>
            )}
                </div>
            </div>

            <Toaster />

            <div className={`modal${openModal ? ' show' : ''}`} role="dialog" style={{ display: openModal ? 'block' : 'none' }}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <CustomerForm setOpenModal={setOpenModal} tagsAdd={tagsAdd} getTags={getTags} setTagsAdd={setTagsAdd} newTags={newTags} setNewTags={setNewTags}
                            tagsSelected={tagsSelected} setTagsSelected={setTagsSelected} handleAddTags={handleAddTags} handleTags={handleTags} tagsError={tagsError}
                             fetchContactsList={fetchContactsList} />
                    </div>
                </div>
            </div>

            <div className={`modal${importModal ? ' show' : ''}`} role="dialog" style={{ display: importModal ? 'block' : 'none' }}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <ContactImport setImportModal={setImportModal} Login_user_id={Login_user_id} CustomerId={CustomerId} fetchContactsList={fetchContactsList} />
                </div>
            </div>

            {
                showEditModal && (
                    <div className="modal" role="dialog" style={{ display: 'block' }}>
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <ContactEditForm loadingEdit={loadingEdit} setShowEditModal={setShowEditModal} tagsAdd={tagsAdd} getTags={getTags}
                                setTagsAdd={setTagsAdd} newTags={newTags} setNewTags={setNewTags} tagsSelected={tagsSelected} setTagsSelected={setTagsSelected} handleAddTags={handleAddTags}
                                handleTags={handleTags} tagsError={tagsError} setTagsError={setTagsError} initialEditValues={initialEditValues}
                                fetchContactsList={fetchContactsList}
                            />
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default Contact