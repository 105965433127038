import React from 'react'
import { Form, Formik, ErrorMessage, Field } from 'formik'
import * as Yup from 'yup'

const BroadcastForm = ({ handleBackButtonTemplate, loading, handleSubmitBroadCast, personNames, segmentCustomers
    , selectedTemplate, selectedVariables, segmentImage
}: any) => {
    const initialBroadCastValues = {
        broadcast_name: '',
        delivery_type: 'immediate',
        schedule_date: '',
        schedule_time: '',
    }

    const validationBroadCast = Yup.object().shape({
        broadcast_name: Yup.string().required('Broadcast Name is required'),
        // delivery_type: Yup.string().required('Delivery Type is required'), 
        // schedule_date: Yup.string()
        //   .test('conditional-required', 'Date is required', function (value) {
        //     const delivery_type = this.parent.delivery_type;
        //     if (delivery_type === 'schedule') {
        //       return !!value;
        //     }
        //     return true;  
        //   }),
        // schedule_time: Yup.string()
        //   .test('conditional-required', 'Time is required', function (value) {
        //     const delivery_type = this.parent.delivery_type;
        //     if (delivery_type === 'schedule') {
        //       return !!value;
        //     }
        //     return true;  
        //   }),
    });

    const replacePlaceholders = (text: any, replacements: any) => {
        let replacedText = text;
        replacements.forEach((value: any, index: any) => {
            const placeholder = `{{${index + 1}}}`;
            replacedText = replacedText.split(placeholder).join(value);
        });
        return replacedText;
    };
    
    return (
        <>
            <h5 className='text-muted'>
                <i className="bi bi-arrow-left fs-3 text-dark pe-3 cursor-pointer" onClick={handleBackButtonTemplate}></i> Select Segment
                <i className="bi bi-chevron-right fs-3 text-dark pe-3"></i> Select template
                <i className="bi bi-chevron-right fs-3 text-dark ps-3"></i>
                <span className='text-dark ps-3'>Preview</span>
            </h5>
            <div className="row mt-5">
                <div className='col-md-8 offset-md-2 card p-8'>
                    <Formik validationSchema={validationBroadCast} initialValues={initialBroadCastValues} onSubmit={handleSubmitBroadCast}>
                        {({ values }) => (
                            <Form>
                                <div className="form-group d-flex">
                                    <label className="form-label mt-3 mb-3 me-5" htmlFor="broadcast_name" >Broadcast Name : </label>
                                    <Field type="text" name="broadcast_name" className="form-control w-50" />
                                </div>
                                <ErrorMessage name="broadcast_name" component="div" className="text-danger" />
                                <div className="form-group d-flex mt-10">
                                    <label className='form-label me-10'>Delivery Type : </label>
                                    <div className="form-check form-check-custom form-check-solid me-10">
                                        <Field
                                            type="radio"
                                            name="delivery_type"
                                            value="immediate"
                                            id="immediate"
                                            className="form-check-input"
                                            checked="checked"
                                        />
                                        <label className="form-check-label fw-bolder text-gray-500 text-hover-primary fs-5" htmlFor="immediate">Immediate</label>
                                    </div>
                                    {/* <div className='form-check form-check-custom form-check-solid'>
                        <Field
                          type="radio"
                          name="delivery_type"
                          value="schedule"
                          id="schedule"
                          className="form-check-input"
                        />
                        <label className="form-check-label fw-bolder text-gray-500 text-hover-primary fs-5" htmlFor="schedule">Schedule</label>
                      </div> */}
                                </div>
                                <ErrorMessage name="delivery_type" component="div" className="text-danger" />
                                {values.delivery_type === 'schedule' && (
                                    <div className="form-group d-flex mt-10">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="schedule_date">Date</label>
                                            <Field type="date" name="schedule_date" min={new Date().toISOString().split("T")[0]} className="form-control" placeholder="Schedule" />
                                            <ErrorMessage name="schedule_date" component="div" className="text-danger" />
                                        </div>
                                        <div className="form-group ms-5">
                                            <label className="form-label" htmlFor="schedule_time">Time</label>
                                            <Field type="time" name="schedule_time" className="form-control" />
                                            <ErrorMessage name="schedule_time" component="div" className="text-danger" />
                                        </div>
                                    </div>
                                )}
                                <div className='d-flex justify-content-end mt-5'>

                                    <div className='d-flex justify-content-end'>
                                        <button className="btn btn-primary" type="submit" disabled={loading}>
                                            {!loading ? 'Submit' : (
                                                <span className='indicator-progress' style={{ display: 'inline-block' }}>
                                                    Please wait...{' '}
                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                </span>
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>

            <div className="row mt-5">
                <div className='col-md-8 offset-md-2 card p-8'>
                    <div>
                        <h4 className="ms-3 text-muted">
                            <span className='text-dark'><span className='badge badge-success'>{personNames.flat().length}</span> Contacts Selected</span>
                        </h4>

                        <span className="fw-bold border border-success badge badge-info-success rounded p-2 mt-5 fs-6">
                            {segmentCustomers.map((item: any) => item.segment_name).join(', ')}
                        </span>
                    </div>
                </div>
            </div>

            <>
                {selectedTemplate && selectedTemplate.components
                    .filter((component: any) =>
                    (component.type === 'BODY' && typeof component.text === 'string'
                    ))
                    .map((filteredComponent: any, index: any) => (
                        <div key={index}>
                            {personNames.map((personDetail: any, personIndex: any) => {
                                // console.log('Person Detail:', personDetail);

                                if (personDetail && typeof personDetail === 'object') {
                                    const replacementValues = selectedVariables?.map((item: any) => {
                                        return personDetail.hasOwnProperty(item) ? personDetail[item] : null;
                                    }) || [];

                                    return (
                                        <div key={personIndex} className="row mt-5">
                                            <div className="col-md-8 offset-md-2 card p-8">
                                                <div className="mt-5">
                                                    <>
                                                        {personIndex === 0 && (
                                                            <>
                                                                {segmentImage && (segmentImage as string).toLowerCase().endsWith('.mp4') ? (
                                                                    <div className="d-flex justify-content-center mb-2">
                                                                        <video src={segmentImage} controls className="w-50">
                                                                            Your browser does not support the video tag.
                                                                        </video>
                                                                    </div>
                                                                ) : (
                                                                    <div className="d-flex justify-content-center mb-2">
                                                                        {segmentImage && (segmentImage as string).toLowerCase().endsWith('.pdf') ? (
                                                                            // Display PDF view
                                                                            <embed src={segmentImage} type="application/pdf" className="w-50" />
                                                                        ) : (
                                                                            // Display image
                                                                            <img src={segmentImage ? segmentImage : undefined} className='w-50' />
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}

                                                        <p style={{ whiteSpace: 'pre-wrap' }}>
                                                            {replacePlaceholders(
                                                                filteredComponent.text,
                                                                replacementValues
                                                            )}
                                                        </p>
                                                    </>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                } else {
                                    console.error('Invalid personDetail type:', typeof personDetail);
                                    return null;
                                }
                            })}
                        </div>
                    ))}
            </>
        </>
    )
}

export default BroadcastForm