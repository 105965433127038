import Swal from 'sweetalert2';



const maxSize = 2 * 1024 * 1024; // 2 megabytes

const displayErrorMessage = (title: string, text: string) => {
    Swal.fire({
        icon: 'error',
        title,
        text,
    });
};

const compressImage = (file: File): Promise<File> => {
    return new Promise((resolve, reject) => {
        const image = new Image();
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        image.onload = () => {
            // Set the canvas dimensions to desired size
            const maxWidth = 800; // Adjust this value based on your requirements
            const maxHeight = 800; // Adjust this value based on your requirements
            let width = image.width;
            let height = image.height;

            if (width > height) {
                if (width > maxWidth) {
                    height *= maxWidth / width;
                    width = maxWidth;
                }
            } else {
                if (height > maxHeight) {
                    width *= maxHeight / height;
                    height = maxHeight;
                }
            }

            canvas.width = width;
            canvas.height = height;

            // Draw the image on the canvas with the new dimensions
            ctx?.drawImage(image, 0, 0, width, height);

            // Convert the canvas content back to a Blob
            canvas.toBlob(
                (blob) => {
                    const compressedFile = new File([blob as Blob], file.name, { type: file.type, lastModified: Date.now() });
                    resolve(compressedFile);
                },
                file.type,
                0.6 // Compression quality (adjust as needed)
            );
        };

        image.onerror = (error) => {
            console.error('Image loading error:', error);
            reject(error);
        };

        // Load the image
        const reader = new FileReader();
        reader.onload = (e) => {
            if (e.target?.result) {
                image.src = e.target.result as string;
            }
        };
        reader.readAsDataURL(file);
    });
};

const handleImageDrop = async (acceptedFiles: any, setImageFunction: any) => {
    const file = acceptedFiles[0];

    if (file) {
        try { 
            const compressedFile = await compressImage(file); 
            if (compressedFile.size <= maxSize) {
                setImageFunction([compressedFile]);
            } else {
                displayErrorMessage('Image Size Error', `The compressed image still exceeds the allowed limit of ${maxSize / (1024 * 1024)} MB.`);
            }
        } catch (error) {
            displayErrorMessage('Image Processing Error', 'An error occurred while processing the image.');
        }
    }
};

export { handleImageDrop };