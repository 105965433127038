import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_orange.css';
import { orders_get_reports } from '../auth/core/_requests';
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers';
import PaginationNew from '../../pages/PaginationNew';


interface DataRow {
    order_id: number;
    delivery_charges: string;
    order_created_on: string;
    order_grand_total: string;
    order_gst_amount: string;
    order_gst_perc: string;
    order_payment_status: string;
    order_sub_total: string;
    order_person_name: string;
    order_contact_number: string;
}

const OrdersReport: React.FC = () => {
    const [showPerPage, setShowPerPage] = useState(10);
    const [orderReport, setOrderReport] = useState<DataRow[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredRecords, setfilteredRecords] = useState<DataRow[]>([]);
    const [paymentStatus, setPaymentStatus] = useState('');
    const [dateState, setDateState] = useState<any>({
        startDate: '',
        endDate: ''
    });

    const fetchOrdersReport = async (order_report: any) => {
        try {
            const response = await orders_get_reports(order_report);
            setOrderReport(response.data.order_report);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching members:', error);
        }
    };

    useEffect(() => {
        fetchOrdersReport("0");
    }, []);

    function jsonToExcel(jsonData: any[]) {
        const ws = XLSX.utils.json_to_sheet(jsonData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        return excelBuffer;
    }

    function downloadExcel(data: any[], filename: string) {
        const excelBuffer = jsonToExcel(data);
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style.display = 'none';
        a.href = url;
        a.download = filename;
        a.click();
        URL.revokeObjectURL(url);
    }

    const handleDownloadReport = async () => {
        try {
            const modifiedData = orderReport
            downloadExcel(modifiedData, 'Orders Report.xlsx');
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleFilterExport = async () => {
        try { 
            const modifiedFilteredData = filteredRecords  
            downloadExcel(modifiedFilteredData, 'Orders Report.xlsx');
        } catch (error) {
            console.error('Error exporting filtered data:', error);
        }
    };

    const clearDate = () => {
        setDateState({ date: null });
    };

    useEffect(() => {
        const filtered = orderReport?.filter((item) => {
            const fullName = `${item.order_id} ${item.order_person_name} ${item.order_contact_number}`.toLowerCase();
            return fullName.includes(searchQuery.toLowerCase()) &&
                (paymentStatus === '' || item.order_payment_status === paymentStatus);
                
        });
        setfilteredRecords(filtered);
    }, [searchQuery, orderReport, paymentStatus]);

    useEffect(() => {
        const filteredOrders = orderReport?.filter((item) => { 
            const createdDate = new Date(item.order_created_on.replace(/(\d{2})-(\d{2})-(\d{4})/, '$3-$2-$1'));
            return (
                (paymentStatus === '' || item.order_payment_status === paymentStatus) &&
                (!dateState.startDate || createdDate >= dateState.startDate) &&
                (!dateState.endDate || createdDate <= dateState.endDate)
            );
        });
        setfilteredRecords(filteredOrders);
    }, [orderReport, paymentStatus, dateState]);

    const handlePaymentStatusChange = (event: any) => {
        setPaymentStatus(event.target.value);
    };

    const handleItemsPerPageChange = (perPage: number) => {
        setShowPerPage(perPage);
        setCurrentPage(1);
    };

    const itemsPerPage = 10;
    const totalItems = filteredRecords.length;
    const filterDataTotal = filteredRecords.length
    const totalPages = Math.ceil(totalItems / showPerPage);
    const filterTotalPage = Math.ceil(filterDataTotal / showPerPage);
    const startIndex = (currentPage - 1) * showPerPage;
    const endIndex = startIndex + showPerPage;
    const totalOrders = filteredRecords.slice(startIndex, endIndex);

    return (


        <div className='card'>
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>ORDER REPORT</span>
                    <span className='text-muted mt-1 fw-semibold fs-7'>Total {orderReport.length} orders</span>
                </h3>
                <div className='card-toolbar'>
                <button className="btn btn-primary me-5" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                        <i className="las la-filter fs-2" />
                    </button>
                    <div className="d-flex align-items-center">
                        <div className="input-group">
                            <input type="search" placeholder='Search Name & Phone...'
                                value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)}
                                className="form-control w-50" />
                            <button type="button" className="btn btn-primary">
                                <i className="fas fa-search"></i>
                            </button>
                        </div>
                    </div> 
                    <button type="button" className="btn btn-primary ms-5" onClick={handleDownloadReport}>
                        <KTIcon iconName='file-down' className='fs-2' />
                        Export
                    </button>
                </div>
            </div>

            <div className="collapse" id="collapseExample">
                <div className='card bg_none'>
                    <div className='card-header border-0 pt-5'>
                        <h3 className='card-title align-items-start flex-column'>
                        </h3>
                        <div className='card-toolbar'>
                            <div className='d-flex me-5'>
                                <Flatpickr
                                    value={dateState.date}
                                    onChange={([startDate, endDate]) => {
                                        setDateState({ startDate, endDate });
                                    }}
                                    options={{
                                        mode: "range",
                                    }}
                                    className='form-control form-control-solid'
                                    placeholder='Pick date'
                                />
                                {dateState.startDate && dateState.endDate && (
                                    <button className="btn btn-sm btn-secondary" onClick={clearDate}>
                                        <KTIcon iconName='abstract-11' className='' />
                                    </button>
                                )}
                            </div>

                            <div className='me-5'>
                                <select
                                    className='form-select form-select-solid'
                                    onChange={handlePaymentStatusChange}
                                    value={paymentStatus}
                                >
                                    <option value="" >Payment Status</option>
                                    <option value="1">Paid</option>
                                    <option value="0">Not Paid</option>

                                </select>
                            </div>
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={handleFilterExport}
                                disabled={!paymentStatus && !searchQuery && !dateState.startDate && !dateState.endDate}
                            >
                                <KTIcon iconName='file-down' className='fs-2 me-2' />
                                Export Filtered
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className='card-body py-3'>
                {isLoading ? (
                    <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ) : (totalOrders.length > 0 ? (
                    <div className='mb-5'>
                        <table className='table align-middle gs-0 gy-4'>
                            <thead>
                                <tr className='fw-bold fs-6 bg-light'>
                                    <th className='ps-4 rounded-start text-center'>Order Id</th>
                                    <th > Name</th>
                                    <th > Contact</th>
                                    <th >Subtotal</th>
                                    <th >GST</th>
                                    <th >Delivery Charges</th>
                                    <th > Grand Total</th>
                                    <th >Payment</th>
                                    <th className=' pe-4 rounded-end'>Order On</th>
                                </tr>
                            </thead>
                            <tbody>
                                {totalOrders.map((report, index) => (
                                    <tr key={index}>
                                        <td className='text-dark fw-bold text-hover-primary fs-6 ps-4'>{report.order_id}</td>
                                        <td>{report.order_person_name}</td>
                                        <td>{report.order_contact_number}</td>
                                        <td>{report.order_sub_total}</td>
                                        <td>{report.order_gst_amount} (18%)</td>
                                        <td>{report.delivery_charges ? report.delivery_charges : "-"}</td>
                                        <td>{report.order_grand_total}</td>
                                        <td>{report.order_payment_status === "1" ? (
                                            <span className='badge bg-success text-white'>Paid</span>
                                        ) : (<span className='badge bg-danger text-white'>No Paid</span>)
                                        }</td>
                                        <td>{report.order_created_on}</td>
                                    </tr>
                                ))
                                }
                            </tbody>
                        </table>
                        {searchQuery || paymentStatus ? (
                            <PaginationNew
                                currentPage={currentPage}
                                totalPages={filterTotalPage}
                                itemsPerPage={itemsPerPage}
                                handlePageChange={setCurrentPage}
                                handleItemsPerPageChange={handleItemsPerPageChange}
                            />
                        ) : (
                            <PaginationNew
                                currentPage={currentPage}
                                totalPages={totalPages}
                                itemsPerPage={showPerPage}
                                handlePageChange={setCurrentPage}
                                handleItemsPerPageChange={handleItemsPerPageChange}
                            />
                        )}
                    </div>
                ) :
                    <div className="d-flex justify-content-center p-10">
                        <div className="car">
                            <img src={toAbsoluteUrl('/media/stock/coming_soon.png')} className='w-350px' alt='coming soon' />
                            <h3 className="text-center">No orders found</h3>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default OrdersReport;
