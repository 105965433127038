import { toAbsoluteUrl } from '../../../_metronic/helpers'

const Orders_Print = ({viewOrders}:any) => {
    return (
        <>
            <div className='print_details'>
                <div>
                    <img src={toAbsoluteUrl('/media/logos/logo.png')} alt="logo" className="print-logo" />
                </div>
                <div className='vendor_address'>
                    <h4>EBONY FOODS PRIVATE LIMITED</h4>
                    <p className='fw-bold'>No 319 Mission Street,</p>
                    <p className='fw-bold'>Pondicherry - 605001.India</p>
                    <p className='fw-bold'>+91 9786599854,0413 222 1191</p>
                    <p className='fw-bold'>GST Number : 34AACCE5066D1ZZ</p>
                </div>
            </div>
            <div className='print_details'>
                <div>
                    <h4 className="invoice-title">INVOICE</h4> 
                    <h6 className='fw-bold'><span className='fw-bold fs-6 text-dark'>{viewOrders?.contact_person}</span></h6>
                    <h6 className='fw-bold'>{viewOrders?.address}</h6>
                    <h6 className='fw-bold'><span className='fw-bold fs-6 text-dark'>{viewOrders?.contact_number}</span></h6>
                    <h3 className="total_items badge bg-warning">
                        Total Items: {viewOrders?.order_items?.length}
                    </h3>
                </div>
                <div className='vendor_address'>
                    <p className='fw-bold invoice-title'>Order Id :  {viewOrders?.order_id} </p>
                    <p className='fw-bold text-dark'>Order On :  {viewOrders?.order_on} </p>
                    <p className='fw-bold text-dark'>Order Status :
                        {viewOrders?.order_status === "0" ? (
                            <span className='badge bg-danger text-white ms-2'>Pending</span>
                        ) : viewOrders?.order_status === "1" ? (
                            <span className='badge bg-warning text-white ms-2'>Processing</span>
                        ) : viewOrders?.order_status === "3" ? (
                            <span className="badge bg-success text-white ms-2">Completed</span>
                        ) : viewOrders?.order_status === "2" ? (
                            <span className='badge bg-info text-white ms-2'>Rejected</span>
                        ) : null}
                    </p>
                    <p className='fw-bold text-dark'>Delivery Type :
                        <span>
                            {viewOrders?.delivery_type === 'pickup' || viewOrders?.delivery_type === 'Pickup' ? (
                                <span className='fw-bold ms-2'>
                                    Pickup
                                </span>
                            ) : viewOrders?.delivery_type === 'delivery' || viewOrders?.delivery_type === 'Delivery' ? (
                                <span className='fw-bold ms-2 fs-7'>
                                    Home Delivery
                                </span>
                            ) : ('')}
                        </span>
                    </p>
                    <p className='fw-bold text-muted'>Expected Date : <span className='fw-bold fs-7 text-dark'>{viewOrders?.expected_date}</span></p>

                </div>
            </div>
            <div className='table-responsive'>
                <table className='table align-middle gs-0 gy-4 mt-3'>
                    <thead>
                        <tr className='fw-bold bg-dark print_table_color'>
                            <th className='ps-4 min-w-250px'>Item Name</th>
                            <th className='text-center min-w-200px'>Qty</th>
                            <th className='min-w-100px'>Price</th>
                            <th className='min-w-120px pe-4'>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {viewOrders?.order_items.map((item:any, index:any) => (
                            <tr key={index}>
                                <td className='text-dark fw-bold text-hover-primary fs-6'>
                                    {item.order_item_name}
                                </td>
                                <td className='text-dark fw-bold text-hover-primary fs-6 text-center'>
                                    {item.order_item_qty}
                                </td>
                                <td className='text-dark fw-bold text-hover-primary fs-6'>
                                    {item.order_item_price}
                                </td>
                                <td className='text-dark fw-bold text-hover-primary fs-6'>
                                    {item.order_item_inline_total}
                                </td>
                            </tr>
                        ))
                        }
                    </tbody>
                </table>
                <hr />
                <br />
                <h6 className='fw-bold text-muted align-right'>GST: {viewOrders?.gst}</h6>

                <h2 className='fw-bold text-muted align-right'>Total : {viewOrders?.order_total}</h2>

            </div>
            <h6 className='fw-bold text-muted'>Notes : <span className='fw-bold fs-6 text-dark'>{viewOrders?.special_instructions}</span></h6>
        </>
    )
}

export default Orders_Print