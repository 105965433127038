
import LeftSide from './LeftSide';
import LoginForm from './LoginForm';

const Login = () => { 
  return (
    <> 
      <div className='row me-0'>
        <div className='col-md-6 login_leftside_image'>
          <LeftSide />
        </div>
        <div className='col-md-6 login_form'>
          <LoginForm />
        </div>
      </div>
    </>
  )
}

export default Login