import {GET_WA_MESSAGE_TEMPLATE } from "../../modules/auth/core/_requests";

export const GET_TEMPLATES = async (access_token: any, setApiData: Function) => {
    try {
        const response = await GET_WA_MESSAGE_TEMPLATE(access_token);
        setApiData(response.data);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

