import React, { useEffect, useState } from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../../../../_metronic/helpers'
import Loader from '../../../../../pages/Loader'
import { WABUDATA } from '../../../../auth/core/_requests';
import { WhatsappUserData } from '../Chat_Interface/Chat_Interface';

export const fetchwaUserData = async (wacustData: any, setwatUserData: any, setisloading: any) => {
    try {
        const response = await WABUDATA(wacustData);
        setwatUserData(response.data.wa_user_data);
        setisloading(false);
    } catch (error) {
        console.error('Error fetching chats:', error);
    }
};

const ChatList = ({ handleContactModel, chatStart, chatuser }: any) => {
    const [isloading, setisloading] = useState(true);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [waUserData, setwaUserData] = useState<WhatsappUserData[]>([]);

    const backgroundColors = ["bg-primary", "bg-dark", "bg-success", "bg-danger", "bg-warning", "bg-info"];



    useEffect(() => {
        fetchwaUserData("get_all_wa_user", setwaUserData, setisloading);
    }, []);

    const filteredUserData = waUserData.filter((userData: any) => {
        // Filter based on name or number
        return (
            userData.sender_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            userData.wa_id.includes(searchQuery)
        );
    });

    return (
        <>
            <div className='mb-10 mb-lg-0'>
                <div className='card shadow-none' style={{ height: "82vh" }}>
                    <div className='card-header p-3' id='kt_chat_contacts_header'>
                        <h4 className='card-title align-items-start flex-column'>
                            <div className='symbol symbol-30px symbol-circle me-3'>
                                <img alt='Pic' src={toAbsoluteUrl('/media/whatsapp/whatsapp.svg')} />
                                <span className='card-label fw-bold fs-3 mb-1 ms-1'>Whatsapp Chats</span>
                            </div>
                        </h4>
                        <div className='card-toolbar'>
                            <div className=''>
                                <button className='btn btn-sm btn-primary' onClick={handleContactModel}>
                                    <KTIcon iconName='plus' className='fs-2' />
                                    New
                                </button>
                            </div>
                            {/* <div className='ps-4'>
                                <button type="button" className="btn btn-sm btn-primary" data-bs-toggle="collapse" data-bs-target="#search_chats" aria-expanded="false" aria-controls="search_chats">
                                    <i className="fas fa-search"></i>
                                </button>
                            </div> */}
                        </div>
                    </div>
                    <div className='card-body p-3' id='kt_chat_contacts_body'>
                        {/* <div className="collapse mb-5 w-100" id="search_chats"> */}
                            <div className='position-relative mb-5'>
                                <KTIcon
                                    iconName='magnifier'
                                    className='fs-2 text-lg-1 text-gray-500 position-absolute top-50 ms-5 translate-middle-y'
                                />
                                <input
                                    type='text'
                                    className='form-control form-control-solid px-15'
                                    name='search'
                                    placeholder='Search name or number...'
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                            </div>
                        {/* </div> */}
                        {/* <ul className="nav nav-tabs nav-line-tabs nav-line-tabs-2x d-flex align-items-center justify-content-between">
                            <li className="nav-item">
                                <a
                                    className="nav-link active"
                                    data-bs-toggle="tab"
                                    href="#all_chats"
                                >
                                    <KTIcon iconName='abstract-26' className='fs-2x text-primary' />
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    data-bs-toggle="tab"
                                    href="#new_chat"
                                >
                                    <KTIcon iconName='user-tick' className='fs-2x text-primary' />
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    data-bs-toggle="tab"
                                    href="#mention_chat"
                                >
                                    <i className="bi bi-at fs-2x text-primary"></i>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    data-bs-toggle="tab"
                                    href="#pin_chat"
                                >
                                    <div style={{ transform: 'rotate(45deg)' }}>
                                        <i className="bi bi-pin fs-2x text-primary"></i>
                                    </div>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    data-bs-toggle="tab"
                                    href="#unresolved_chat"
                                >
                                    <i className="bi-chat-left-dots fs-1 text-primary"></i>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    data-bs-toggle="tab"
                                    href="#resolved_chat"
                                >
                                    <i className="bi-check-square fs-1 text-primary"></i>
                                </a>
                            </li>
                        </ul> */}

                        {/* <div className="tab-content" id="myTabContent"> */}
                            {/* <div
                                className="tab-pane fade show active"
                                id="all_chats"
                                role="tabpanel"
                            > */}
                                {isloading ? (
                                    <Loader />
                                ) : (filteredUserData.length > 0 ? (
                                    <div style={{ overflowY: 'auto', maxHeight: '60vh' }}>
                                        {filteredUserData.map((userData, index) => (
                                            <>
                                                <div
                                                    key={userData.wa_id}
                                                    className={`d-flex flex-stack align-content-top py-2  ${userData.wa_id === chatuser ? 'bg-light-info ' : ''}`}
                                                    onClick={() => chatStart(userData.wa_id)}
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    <div className='d-flex align-items-top'>
                                                        <div className="symbol symbol-35px symbol-circle">
                                                            <span className={`symbol-label fs-2 fw-bold ${backgroundColors[index % backgroundColors.length]} text-inverse-primary`}>
                                                                {
                                                                    (userData?.sender_name && /^[A-Za-z]/.test(userData.sender_name))
                                                                        ? userData.sender_name.charAt(0).toUpperCase()
                                                                        : (userData?.wa_id && userData.wa_id.charAt(0))
                                                                }
                                                            </span>
                                                        </div>
                                                        <div className='ms-2'>
                                                            <span className='fs-6 fw-bolder text-gray-700 text-hover-primary wa-number'>
                                                                {userData.sender_name}
                                                            </span>
                                                            <p>{userData.wa_id}</p>
                                                        </div>
                                                    </div>
                                                    <div style={{ marginLeft: 'auto' }}>
                                                        <div className='d-flex flex-column align-items-end'>
                                                            <span className='text-muted fs-7 wa-time'>{userData.last_message_on}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='separator separator-dashed'></div>
                                            </>
                                        ))
                                        }
                                    </div>
                                ) :
                                    <div className="d-flex justify-content-center p-10">
                                        <div>
                                            <img src={toAbsoluteUrl('/media/stock/coming_soon.png')} className='w-350px' alt='coming soon' />
                                            <h3 className="text-center">No chats found</h3>
                                        </div>
                                    </div>
                                )}
                            {/* </div> */}
                            {/* <div className="tab-pane fade" id="new_chat" role="tabpanel">
                                <div className=''>
                                    New Chat
                                </div>
                            </div>
                            <div className="tab-pane fade" id="mention_chat" role="tabpanel">
                                Mention chat
                            </div>
                            <div className="tab-pane fade" id="pin_chat" role="tabpanel">
                                Pin chat
                            </div>
                            <div className="tab-pane fade" id="unresolved_chat" role="tabpanel">
                                UnResolved chat
                            </div>
                            <div className="tab-pane fade" id="resolved_chat" role="tabpanel">
                                Resolved chat
                            </div> */}
                        {/* </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChatList