/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { KTIcon, KTSVG, toAbsoluteUrl } from '../../../helpers'
import { useLayout } from '../../core'
import { Header } from './Header'
import { Navbar } from './Navbar'

export function HeaderWrapper() {
  const { config, classes } = useLayout()
  if (!config.app?.header?.display) {
    return null
  }

  return (
    <>
      <div id='kt_app_header' className='app-header'>
        <div
          id='kt_app_header_container'
          className={clsx(
            'app-container flex-lg-grow-1',
            classes.headerContainer.join(' '),
            config.app?.header?.default?.containerClass
          )}
        >
          {config.app.sidebar?.display && (
            <>
              <div
                className='d-flex align-items-center d-lg-none ms-n2 me-2'
                title='Show sidebar menu'
              >
                <div
                  className='btn btn-icon btn-active-color-primary w-35px h-35px'
                  id='kt_app_sidebar_mobile_toggle'
                >
                  <KTIcon iconName='abstract-14' className=' fs-1' />
                </div>
                {/* <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
                  <Link to='/dashboard' className='d-lg-none'>
                    <img
                      alt='Logo'
                      src={toAbsoluteUrl('/media/logos/lc_logo_app.png')}
                      className='h-30px'
                    />
                  </Link>
                </div> */}
              </div>
            </>
          )}

          {!(config.layoutType === 'dark-sidebar' || config.layoutType === 'light-sidebar') && (
            <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0 me-lg-15'>
              <Link to='/dashboard'>
                {config.layoutType !== 'dark-header' ? (
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/logos/default.svg')}
                    className='h-20px h-lg-30px app-sidebar-logo-default'
                  />
                ) : (
                  <>
                    <img
                      alt='Logo'
                      src={toAbsoluteUrl('/media/logos/default-dark.svg')}
                      className='h-20px h-lg-30px app-sidebar-logo-default theme-light-show'
                    />
                    <img
                      alt='Logo'
                      src={toAbsoluteUrl('/media/logos/default-small-dark.svg')}
                      className='h-20px h-lg-30px app-sidebar-logo-default theme-dark-show'
                    />
                  </>
                )}
              </Link>
            </div>
          )}

          <div id='kt_app_header_wrapper'
            className='d-flex align-items-stretch justify-content-between flex-lg-grow-1'
          >
            {/* {config.app.header.default?.content === 'menu' &&
              config.app.header.default.menu?.display && (
                <div
                  className='app-header-menu app-header-mobile-drawer align-items-stretch'
                  data-kt-drawer='true'
                  data-kt-drawer-name='app-header-menu'
                  data-kt-drawer-activate='{default: true, lg: false}'
                  data-kt-drawer-overlay='true'
                  data-kt-drawer-width='225px'
                  data-kt-drawer-direction='end'
                  data-kt-drawer-toggle='#kt_app_header_menu_toggle'
                  data-kt-swapper='true'
                  data-kt-swapper-mode="{default: 'append', lg: 'prepend'}"
                  data-kt-swapper-parent="{default: '#kt_app_body', lg: '#kt_app_header_wrapper'}"
                >
                  <Header />
                </div>
              )} */}

            <Navbar />
          </div>
        </div>
      </div>
      <div className="modal fade" tabIndex={-1} id="kt_modal_1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-fullscreen-sm-down">
          <div className="modal-content ">
            <div className="modal-header">
              <h5 className="modal-title"><i className="bi bi-headset fs-2"></i> Support / Help</h5>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <KTSVG
                  path="/media/icons/duotune/arrows/arr061.svg"
                  className="svg-icon svg-icon-2x"
                />
              </div>
            </div>
            <div className="modal-body">
              <select className="form-select form-select-white" aria-label="Select example">
                <option>Select Option</option>
                <option value="1">Support</option>
                <option value="2">Help</option>
              </select>

              <div className="form-floating mt-3">
                <textarea className="form-control h-150px" placeholder="Leave a comment here" id="floatingTextarea"></textarea>
                <label htmlFor="floatingTextarea">Enter Your Queries Here...</label>
              </div>
            </div>
            <div className="modal-footer">

              <button type="button" className="btn btn-success">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
