import React, { useState } from 'react'
import { handleImageDrop } from '../../../../../pages/CompressImage'; 
import { useDropzone } from 'react-dropzone';
import { Field, Form, Formik, FormikValues } from 'formik'
import { whatspp_chat_image_post } from "../../../../auth/core/_requests"
import { KTIcon } from '../../../../../../_metronic/helpers'; 

const ChatImage = ({ chatuser, setImageModel, chatStart }: any) => {
    const [loading, setLoading] = useState(false)
    const [imageUpload, setImageUpload] = useState<File[]>([]);
    const [frontImageError, setFrontImageError] = useState(false);
    const sender_id = "919943497572";

    const { getRootProps: getRootProps2, getInputProps: getInputProps2 } = useDropzone({
        onDrop: acceptedFiles => handleImageDrop(acceptedFiles, setImageUpload),
        accept: {
            'image/jpeg': [],
            'image/png': [],
        }, multiple: false,
    });

    const handleClear = () => {
        setImageUpload([]); // Clear uploaded images
    };

    const handleSubmit = async (values: any, actions: FormikValues) => {
        if (!imageUpload[0]) {
            setFrontImageError(true);
            return;
        } else {
            setFrontImageError(false);
        }
        setLoading(true);
        try {
            const ChatMedia = new FormData();
            if (imageUpload.length > 0) {
                ChatMedia.append("chat_media", imageUpload[0]);
            }
            const response = await whatspp_chat_image_post(
                ChatMedia,
                'image',
                values.message_caption ? values.message_caption : '',
                chatuser,
                sender_id
            );
            setLoading(false);
            chatStart(chatuser);
            setImageModel(false)
            actions.resetForm();
            setImageUpload([]);

        } catch (error) {
            console.error('Error reading file:', error);
            setLoading(false);
        }
    };
    return (
        <>
            <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title">
                            <h3>Image Upload</h3>
                        </div>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => setImageModel(false)} aria-label="Close"></button>
                    </div>
                    <Formik initialValues={{ file: null }} onSubmit={handleSubmit} >
                        <Form>
                            <div className="modal-body">
                                <div {...getRootProps2({ className: "dropzone" })} >
                                    <input className="input-zone" {...getInputProps2()} name='file' />
                                    <div className="text-center">
                                        <p className="dropzone-content">
                                            Drag’n’drop or click to select files
                                        </p>
                                    </div>
                                </div>
                                {frontImageError && <div className="text-danger mt-2">Please upload a image.</div>}
                                {/* <div className='d-flex justify-content-end mt-5'>
        <button type="button" style={{ display: imageUpload.length > 0 ? 'inline-block' : 'none' }} className="btn btn-sm btn-danger" onClick={handleClear}>Clear </button>
    </div> */}
                                <div>
                                    {imageUpload.map(file => (
                                        <div key={file.name} className='chat_image_preview_file'>
                                            <img
                                                src={URL.createObjectURL(file)}
                                                alt={file.name}
                                                className='w-100'
                                            />
                                            <div style={{ position: 'absolute', top: '5px', right: '5px', cursor: "pointer" }} onClick={() => handleClear()} >
                                                <div className="symbol symbol-circle symbol-25px">
                                                    <div className="symbol-label">
                                                        <KTIcon iconName='cross-square' className='fs-1 text-danger' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                {imageUpload.length > 0 && (
                                    <div className="form-group mt-5 mb-3">
                                        <Field
                                            name="message_caption"
                                            className="form-control w-50"
                                            as="textarea"
                                            placeholder="Add a Caption..."
                                        />
                                    </div>
                                )} 
                            </div>

                            <div className="modal-footer">
                                {/* <div className="row col-12"> */}
                                <button type='submit' className='btn btn-primary' disabled={loading}>
                                    {!loading && 'Send'}
                                    {loading && (
                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                            Please wait...{' '}
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                </button>
                                {/* </div> */}
                            </div>
                        </Form>
                    </Formik>
                </div>
            </div>
        </>
    )
}

export default ChatImage 