import React, { useState } from 'react'
import { ErrorMessage, Field, Form, Formik, FormikValues } from 'formik'
import * as Yup from 'yup'
import { PAYMENT_REQUEST_SEND } from "../../../../auth/core/_requests"

const PaymentModel = ({ setPaymentModal, chatuser }: any) => { 
    const [loading, setLoading] = useState(false)

    const UserDetails: any = localStorage.getItem('user-details')
    const userDetailsObject = JSON.parse(UserDetails);
    const user_id = userDetailsObject?.user_id

    const validationSchema = Yup.object().shape({
        payment: Yup.string().required('Payment is required'),
        payment_notes: Yup.string().required('Notes is required'),
    });

    const initialValues = {
        payment: '',
        payment_notes: '',
    }

    const handleSubmit = async (values: any, actions: FormikValues) => {
        setLoading(true);
        try {
            const response = await PAYMENT_REQUEST_SEND(
                values.payment,
                values.payment_notes,
                chatuser,
                user_id
            )
            setLoading(false)
            setPaymentModal(false)
            actions.resetForm();
        } catch (error) {
            console.error('Error reading file:', error);
            setLoading(false);
        }
    };



    return (
        <>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Payment Request :</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => setPaymentModal(false)} aria-label="Close"></button>
                    </div>
                    <Formik
                        validationSchema={validationSchema}
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                    >
                        <Form>
                            <div className="modal-body">
                                <div className="form-group">
                                    <label className="form-label mt-3 mb-3 required" htmlFor="payment">Amount</label>
                                    <div className="input-group">
                                        <Field name="country_rupees" as="select" className="form-select w-25" id="phone">
                                            <option value="INR">INR</option>
                                        </Field>
                                        <Field type="number" name="payment" className="form-control form-control-lg w-75" />
                                    </div>
                                    <ErrorMessage name="country_rupees" component="div" className="text-danger" />
                                    <ErrorMessage name="payment" component="div" className="text-danger" />
                                </div>
                                <div className="form-group">
                                    <label className="form-label required mt-3 mb-3" htmlFor="payment_notes">Payment For</label>
                                    <Field
                                        name="payment_notes"
                                        className="form-control"
                                        as="textarea"
                                        placeholder="Add a note to your payment request message"
                                    />
                                    <ErrorMessage name="payment_notes" component="div" className="text-danger" />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div className="row col-12">
                                    <button type='submit' className='btn btn-primary' disabled={loading}>
                                        {!loading && 'Send'}
                                        {loading && (
                                            <span className='indicator-progress' style={{ display: 'block' }}>
                                                Please wait...{' '}
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        )}
                                    </button>
                                </div>
                            </div>
                        </Form>
                    </Formik>
                </div>
            </div>
        </>
    )
}

export default PaymentModel